

import React from 'react';
import { getSpellings, midiToName } from '../utils';
import * as Tone from 'tone';

// function getSpellings(key = 0, mode = 0) {
//   const names = [
//     ['C', 'C'],
//     ['G', 'G'],
//     ['D', 'D'],
//     ['A', 'A'],
//     ['E', 'E'],
//     ['B', 'B'],
//     ['F#', 'Gb'],
//     ['C#', 'Db'],
//     ['G#', 'Ab'],
//     ['D#', 'Eb'],
//     ['A#', 'Bb'],
//     ['F', 'F']
//   ];

//   key = (key * 7) % 12;  // circle of 5ths position 0 - 11
//   const minor = 6 <= key && key <= 7 && mode === 1 ? 6 : 0;  // 6 & 7 are F#m & C#m, 8 is G#m instead of Abm
//   let noteNames = Array(12).fill('');
//   for (let i = 0; i < 12; i++) {
//     const note = key <= 6 + minor ?
//       names[i][i <= 6 + minor + key - mode ? 0 : 1] :
//       names[i][i <= 6 + minor + key - mode ? 1 : 0];
//     noteNames[(i * 7) % 12] = note;  // place notes in chromatic order
//   }
//   return noteNames;
// }

// function midiToName(midiNote: number, includeOctave: boolean = false, key: number = 0, mode: number = 0) {
//   const noteNames = getSpellings(key, mode);
//   const noteIdx = midiNote % 12;  // Get note index (+ octave if includeOctave is True)
//   const octave = includeOctave ? Math.floor(midiNote / 12) - 1 : "";
//   return noteNames[noteIdx] + (includeOctave ? octave.toString() : '');
// }


type KeyProps = {
  note: number;
  keyflash: boolean;
  isBlack: boolean;
  style?: React.CSSProperties;
  isActive: boolean;
  rootNote: number;
  toggleNote: (note: number) => void;  // new prop
  toggledNotes: number[];
  keyCenter: number;
  mode: number;
  sampler: Tone.Sampler | null;
  shouldPlaySound: boolean;
};

// const keyCenter = 0;
// const mode = 0;

// Helper function to calculate the pitch class
const pitchClass = (note: number, rootNote: number = 0) => {
  return (note + 12 - rootNote) % 12;
};

const Key: React.FC<KeyProps> = ({ note, keyflash, isBlack, style, isActive, rootNote, toggleNote, toggledNotes, keyCenter, mode, sampler, shouldPlaySound }) => {
  const pc = pitchClass(note, rootNote);
  const pcClass = ['p-root', 'min-second', 'maj-second', 'min-third', 'maj-third', 'p-fourth', 'tritone', 'p-fifth', 'min-sixth', 'maj-sixth', 'min-seventh', 'maj-seventh'][pc];
  const keyClassName = `key ${isBlack ? 'black' : 'white'} ${isActive ? 'active ' + pcClass : ''} ${keyflash ? 'keyflash' : ''}`;
  // const keyClassName = `key ${isBlack ? 'black' : 'white'} ${isActive ? 'active ' + pcClass : ''}`;


  // const handleKeyClick = () => {
  //   toggleNote(note);
  //   if (shouldPlaySound && sampler) {
  //     const noteName = midiToName(note, true);
  //     sampler.triggerAttackRelease(noteName, '3m');
  //   }
  // };

  const handleKeyClick = () => {

    if (shouldPlaySound && sampler) {
      // const noteName = midiToName(note, true);
      const noteName = Tone.Midi(note).toNote();
      if (toggledNotes.includes(note)) {
        // If the note is already toggled on, then we need to stop the sample.
        sampler.triggerRelease(noteName); // Stop the note sound
      } else {
        // If the note isn't toggled, then we need to play the sample.
        sampler.triggerAttack(noteName); // Play the note sound
      }
    }

    // Toggle the note's presence in the toggledNotes array
    toggleNote(note);
  };


  return (
    // <div className={keyClassName} style={style} onClick={() => toggleNote(note)}>
    // <div className={keyClassName} style={style} onClick={() => { handleKeyClick(); toggleNote(note); }}>
    <div title="Click a key to toggle on or off" className={keyClassName} style={style} onClick={handleKeyClick} data-actionable="true">
      <div>
        <div className={`flash-overlay ${keyflash ? 'flash' : ''}`}></div>
        {isActive ? <div className="name-display">{midiToName(note, false, keyCenter, mode)}</div> : null}
      </div>
    </div>
  );
};

// export default React.memo(Key);
export default Key;

