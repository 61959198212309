// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { collection, doc, getFirestore, setDoc } from "firebase/firestore";
import { defaultEmoClassLabelsMajor, defaultEmoClassLabelsMinor } from "./App";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDzIkPEvXLYT5Qjs3mtslQ468vAAfBx-uk",
    authDomain: "sentisonics.firebaseapp.com",
    projectId: "sentisonics",
    storageBucket: "sentisonics.appspot.com",
    messagingSenderId: "122120603900",
    appId: "1:122120603900:web:a51d6ba963aa90eada2f79",
    measurementId: "G-ZXSE7KSCM0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const auth = getAuth(app)
export const firestore = getFirestore(app)