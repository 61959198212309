import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes
} from "react-router-dom";
import { Login } from './pages/Login';
import { TdContest } from './pages/TdContest';


ReactDOM.render(
  <React.StrictMode>

    <div>

      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Router>
        <Routes>
          {/* <Route path="/about" element={<div style={{color: 'white'}}>Hello</div>} /> */}
          <Route path="/login" element={<Login />} />
          <Route path="/signin" element={<div style={{color: 'white'}}>Hello</div>} />
          <Route path="/tdcontest" element={<TdContest/>} />
          <Route path="/" element={<App />} />
        </Routes>
      </Router>
    </div>


  </React.StrictMode>,
  document.getElementById('root')
);
