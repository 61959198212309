


import React, { useState, useRef, RefObject } from 'react';
// import { GettingStarted, KeyCenter, ChordSymbol, Sentiment, CircleOfFifths, PianoDisplay, ChordChart } from './HelpContents';

const Flat = () => (
    <span style={{ fontFamily: 'Bravura Text' }}>♭</span>
);

const Sharp = () => (
    <span style={{ fontFamily: 'Bravura Text' }}>♯</span>
);

const HelpPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const gettingStartedRef = useRef<HTMLDivElement>(null);
    const keyCenterRef = useRef<HTMLDivElement>(null);
    const chordSymbolRef = useRef<HTMLDivElement>(null);
    const sentimentRef = useRef<HTMLDivElement>(null);
    const circleOfFifthsRef = useRef<HTMLDivElement>(null);
    const pianoDisplayRef = useRef<HTMLDivElement>(null);
    const chordChartRef = useRef<HTMLDivElement>(null);

    // const openPopup = () => setIsOpen(true);
    // const closePopup = () => setIsOpen(false);

    const openPopup = () => {
        setIsOpen(true);
        // Prevent scrolling on the main body when the popup is open
        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed'; // Add this line to prevent background scroll
        document.body.style.width = '100%'; // Prevent width change when scrollbar disappears
        document.body.style.height = '100%'; // Prevent height change when scrollbar disappears
    };

    const closePopup = () => {
        setIsOpen(false);
        // Re-enable scrolling on the main body when the popup is closed
        document.body.style.overflow = '';
        document.body.style.position = ''; // Reset position style
        document.body.style.width = ''; // Reset width style
        document.body.style.height = '100%'; // Reset height style
    };

    const scrollToRef = (ref: RefObject<HTMLDivElement>) => {
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    const scrollToTop = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
        // window.scrollTo({
        //     top: 0,
        //     behavior: 'smooth'
        // });
    };

    const ScrollTopButton = () => (
        <p><a onClick={scrollToTop} style={{ cursor: 'pointer', fontStyle: 'italic' }}>Back to top {'\u25b2'}</a></p>
    );


    const GettingStarted = () => {
        return (
            <p>
                <div ref={gettingStartedRef}>
                    <br></br>
                    <p style={{ fontSize: '1.2dvw' }}>1. Getting Started</p>
                    <p>Welcome to the Sentisonics web app BETA. In our pursuit to understand how music evokes emotion so we can become more effective musicians and artists,
                        we've made some discoveries. Sentisonics introduces several concepts you'll want to get familiar with.
                        The first of which is harmony perception.</p>

                    <p>Harmony Perception:</p>
                    <p>When listening to music, there are common perceptions you share with most people who grew up within your culture.
                        Then there are individual perceptions that are unique to you, shaped by your personal life's experience with music,
                        that Sentisonics can help you discover.
                        With a focus on helping artists express themselves, Sentisonics considers emotions functionally.
                        First you need to become aware of what emotions music evokes in you. Then you need to learn exactly how it did so, functionally.
                        And then you'll have the functional tools of expressing emotions through music.</p>
                    <p>What emotions does harmony evoke in you? They're most likely a combination of common and individual perceptions.</p>
                    <p>Jump to the <a onClick={() => scrollToRef(chordSymbolRef)} style={{ cursor: 'pointer' }}>CHORD SYMBOL</a> section for more about harmony perception.</p>

                    <table style={{ width: '95%', padding: '1dvw', borderCollapse: 'collapse' }} >
                        <tr style={{ borderBottom: '1px dashed #333' }}>
                            <td className='userguide' width={'30%'} align='left'
                                style={{
                                    padding: '1dvw',
                                    backgroundColor: '#000',
                                    margin: '1dvw 1dvw 1dvw 1dvw',
                                    verticalAlign: 'top',
                                    fontSize: '1dvw',
                                    fontStyle: 'normal',
                                }}>
                                <p>Sound:</p>

                                <p>a. Sound source: Built-in or external if using MIDI</p>
                                <p>b. Toggle the Sound Off button to Sound On to hear the internal piano sound</p>
                                <p>c. Enable Arpeggiation with the Arp. button.</p>
                                <p>d. -12, -1, 1, 12 buttons move held notes down and up by those steps (half steps)</p>
                            </td></tr></table>
                </div>
                <ScrollTopButton />
            </p>
        );
    };

    const PianoDisplay = () => {
        return (
            <p>
                <div ref={pianoDisplayRef}>
                    <br></br>
                    <p style={{ fontSize: '1.2dvw' }}>2. Piano Display</p>

                    <table style={{ width: '95%', padding: '1dvw', borderCollapse: 'collapse' }} >
                        <tr style={{ borderBottom: '1px dashed #333' }}>
                            <td className='userguide' width={'30%'} align='left'
                                style={{
                                    padding: '1dvw',
                                    backgroundColor: '#000',
                                    margin: '1dvw 1dvw 1dvw 1dvw',
                                    verticalAlign: 'top',
                                    fontSize: '1dvw',
                                    fontStyle: 'normal',
                                }}>
                                <p>a. Three ways to play:</p>
                                <p> {'\u00a0'} {'\u00a0'} 1. MIDI: In the Google Chrome browser Sentisonics will work with a MIDI keyboard or a DAW through a virtual MIDI Device (<a href="https://support.apple.com/guide/audio-midi-setup/transfer-midi-information-between-apps-ams1013/mac">IAC Driver</a> on Mac).
                                </p><p> {'\u00a0'} {'\u00a0'} 2. Mouse: Toggle notes on and off with mouse.
                                </p><p> {'\u00a0'} {'\u00a0'} 3. Computer keys
                                </p><p> {'\u00a0'} {'\u00a0'}
                                    {<img src="/images/HelpContents/computerkeys.jpg" alt="Computer keys" style={{ width: '22dvw' }} />}
                                    <br></br> {'\u00a0'} {'\u00a0'} "Z" Octave down, "X" Octave up{/* , "shift" Sustain */}
                                </p>
                            </td></tr></table>
                </div>
                <ScrollTopButton />
            </p>
        );
    };

    const ChordSymbol = () => {
        return (
            <p>
                <div ref={chordSymbolRef}>
                    <br></br>
                    <p style={{ fontSize: '1.2dvw' }}>3. Chord Symbol</p>
                    <p>
                        <table style={{ width: '95%', padding: '1dvw', borderCollapse: 'collapse' }} >
                            <tr style={{ borderBottom: '1px dashed #333' }}>
                                <td className='userguide' width={'30%'} align='left'
                                    style={{
                                        padding: '1dvw',
                                        backgroundColor: '#000',
                                        margin: '1dvw 1dvw 1dvw 1dvw',
                                        verticalAlign: 'top',
                                        fontSize: '1dvw',
                                        fontStyle: 'normal',
                                    }}>
                                    <br></br>{'\u00a0'}{'\u00a0'}a. Root Perception<br></br>
                                    {<img src="/images/HelpContents/chordsymbol0.Bm69.jpg" alt="Chord Symbols" style={{ width: '22dvw', borderWidth: '0px' }} />}
                                </td>
                                <td className='userguide' width={'70%'} align='center'
                                    style={{
                                        padding: '1dvw',
                                        backgroundColor: '#000',
                                        margin: '1dvw 1dvw 1dvw 1dvw',
                                        verticalAlign: 'top',
                                        fontSize: '1dvw',
                                        fontStyle: 'normal',
                                    }}>
                                    <br></br>The magic of Sentisonics starts here with our Tonal Gravity algorithm which predicts chord-root perception.
                                    Chord symbols are short-hand for understanding the feeling of chords which are grounded by the perceived root.
                                    Chord symbols tell you quickly which notes are included in the chord and their intervalic distance to the root.
                                    "Bm69" tells you it's a Minor chord that includes the 6th and 9th scale degrees, G<Sharp /> and C<Sharp />.
                                    (For details about how we construct chord symbols see this Resource Page.)
                                    The 82.1% shown on the left reveals that the B is most likely perceived as the root, making it a Minor chord.
                                    The 7.1% next to the D∆7<Sharp />11/B reveals that D is the next most likely perceived root, and so on and so forth.
                                    <p>When it comes to the feeling of chords, our brain immediatly reacts emotionally before any symbolic language analysis occurs like chord symbols or sentiment descriptors.
                                        Emotionally, our brain isn't initially concerned with chord symbol naming or how we might notate music on a page.
                                        When it comes to the emotional feeling of a chord, several root contenders may be considered at once.
                                        Therefore, with a focus on emotions, the Sentisonics app displays all possible root contenders (every sounding note in the chord) and a corresponding chord symbol for each of those potential roots.
                                    </p>
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px dashed #333' }}>
                                <td className='userguide' width={'30%'} align='left'
                                    style={{
                                        padding: '1dvw',
                                        backgroundColor: '#000',
                                        margin: '1dvw 1dvw 1dvw 1dvw',
                                        verticalAlign: 'top',
                                        fontSize: '1dvw',
                                        fontStyle: 'normal',
                                    }}>
                                    {'\u00a0'}{'\u00a0'}b. Simultaneous Roots<br></br>
                                    {<img src="/images/HelpContents/chordsymbol2.1.Ab_C.jpg" alt="Chord Symbol Ab/C" style={{ width: '22dvw' }} />}
                                </td>
                                <td className='userguide' width={'70%'} align='center'
                                    style={{
                                        padding: '1dvw',
                                        backgroundColor: '#000',
                                        margin: '1dvw 1dvw 1dvw 1dvw',
                                        fontSize: '1dvw',
                                        fontStyle: 'normal',
                                    }}>
                                    When a chord's root is more ambiguous you may find that several notes may be perceived as root contenders.
                                    This A<Flat /> Major chord sounds similar to a C Minor chord perceptually,
                                    evidenced by A<Flat />'s root strength of 43.1% which is close to C's root strength of 40.9%.
                                    <br></br>
                                    {<img src="/images/HelpContents/chordsymbol2.1.Ab_Cpiano.jpg" alt="Chord Ab/C on piano" style={{ width: '20dvw' }} />}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px dashed #333' }}>
                                <td className='userguide' width={'30%'} align='left'
                                    style={{
                                        padding: '1dvw',
                                        backgroundColor: '#000',
                                        margin: '1dvw 1dvw 1dvw 1dvw',
                                        verticalAlign: 'top',
                                        fontSize: '1dvw',
                                        fontStyle: 'normal',
                                    }}>
                                    {'\u00a0'}{'\u00a0'}c. Root Influencers<br></br>{<img src="/images/HelpContents/chordsymbol2.1.Cm.jpg" alt="Chord Symbol Ab/C" style={{ width: '22dvw' }} />}
                                </td>
                                <td className='userguide' width={'70%'} align='center'
                                    style={{
                                        padding: '1dvw',
                                        backgroundColor: '#000',
                                        margin: '1dvw 1dvw 1dvw 1dvw',
                                        fontSize: '1dvw',
                                        fontStyle: 'normal',
                                    }}>
                                    By changing the inversion or voicing of the chord you can increase the likelhood that another note will win the root perception competition,
                                    which then affects the feeling of the chord.
                                    Adding a C in a low register and its perfect 5th, G, are two ways to increases C's root strength.
                                    This Minor flat 6 chord is used in the dark and eerie "X-Files" TV show theme song.
                                    <br></br>
                                    {<img src="/images/HelpContents/chordsymbol2.1.Cmpiano.jpg" alt="Chord Ab/C on piano" style={{ width: '20dvw' }} />}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px dashed #333' }}>
                                <td className='userguide' width={'30%'} align='left'
                                    style={{
                                        padding: '1dvw',
                                        backgroundColor: '#000',
                                        margin: '1dvw 1dvw 1dvw 1dvw',
                                        verticalAlign: 'top',
                                        fontSize: '1dvw',
                                        fontStyle: 'normal',
                                    }}>
                                    {'\u00a0'}{'\u00a0'}d. Limitless Harmony<br></br>{<img src="/images/HelpContents/chromaticchord.jpg" alt="Chromatic Chord" style={{ width: '22dvw' }} />}
                                </td>
                                <td className='userguide' width={'70%'} align='center'
                                    style={{
                                        padding: '1dvw',
                                        backgroundColor: '#000',
                                        margin: '1dvw 1dvw 1dvw 1dvw',
                                        fontSize: '1dvw',
                                        fontStyle: 'normal',
                                    }}>
                                    No chord is off limits! Here's a chord with all 12 notes.
                                    There's no stumping the Sentisonics chord symbol algorithm, so feel free to get harmonically creative.
                                    <br></br>
                                    {<img src="/images/HelpContents/chromaticchordpiano.jpg" alt="Chromatic 5ths" style={{ width: '32dvw' }} />}
                                </td>
                            </tr>
                            <tr style={{ borderBottom: '1px dashed #333' }}>
                                <td className='userguide' width={'30%'} align='left'
                                    style={{
                                        padding: '1dvw',
                                        backgroundColor: '#000',
                                        margin: '1dvw 1dvw 1dvw 1dvw',
                                        verticalAlign: 'top',
                                        fontSize: '1dvw',
                                        fontStyle: 'normal',
                                    }}>
                                    {'\u00a0'}{'\u00a0'}e. Scales<br></br>{<img src="/images/HelpContents/scalephrygdom.jpg" alt="Scale name" style={{ width: '22dvw' }} />}
                                </td>
                                <td className='userguide' width={'70%'} align='center'
                                    style={{
                                        padding: '1dvw',
                                        backgroundColor: '#000',
                                        margin: '1dvw 1dvw 1dvw 1dvw',
                                        fontSize: '1dvw',
                                        fontStyle: 'normal',
                                    }}>
                                    If you play 7 or more notes within the same octave of the lowest note you'll see a scale name rather than a chord symbol.
                                    Click the scale name to visit a website that explores its names in other cultures and other details.
                                    <br></br>
                                    {<img src="/images/HelpContents/scalephrygdompiano.jpg" alt="Scale piano" style={{ width: '15dvw' }} />}
                                </td>
                            </tr>
                        </table>
                    </p>
                </div>
                <ScrollTopButton />
            </p>
        );
    };

    const Sentiment = () => {
        return (
            <p>
                <div ref={sentimentRef}>
                    <br></br>
                    <p style={{ fontSize: '1.2dvw' }}>4. Sentiment</p>

                    <table style={{ width: '95%', padding: '1dvw', borderCollapse: 'collapse' }} >
                        <tr style={{ borderBottom: '1px dashed #333' }}>
                            <td className='userguide' width={'30%'} align='left'
                                style={{
                                    padding: '1dvw',
                                    backgroundColor: '#000',
                                    margin: '1dvw 1dvw 1dvw 1dvw',
                                    verticalAlign: 'top',
                                    fontSize: '1dvw',
                                    fontStyle: 'normal',
                                }}>
                                <p>
                                    Sentiment palettes are collections of emotions evoked by each note in a chord. The 12 sentiments provided by each note will feel a certain way
                                    if the chord is perceieved as Major but all 12 may feel different if the chord is perceived as Minor. You can adjust the sentiment descriptors
                                    for each note in each of the Major or Minor frameworks. Additionally, you may select any of the default sentiment palettes provided in the drop down menu or
                                    save your own palettes.
                                </p>
                            </td></tr></table>
                </div>
                <ScrollTopButton />
            </p>
        );
    };

    const CircleOfFifths = () => {
        return (
            <p>
                <div ref={circleOfFifthsRef}>
                    <br></br>
                    <p style={{ fontSize: '1.2dvw' }}>5. Circle Of Fifths</p>

                    <table style={{ width: '95%', padding: '1dvw', borderCollapse: 'collapse' }} >
                        <tr style={{ borderBottom: '1px dashed #333' }}>
                            <td className='userguide' width={'30%'} align='left'
                                style={{
                                    padding: '1dvw',
                                    backgroundColor: '#000',
                                    margin: '1dvw 1dvw 1dvw 1dvw',
                                    verticalAlign: 'top',
                                    fontSize: '1dvw',
                                    fontStyle: 'normal',
                                }}>
                                <p>
                                    Each scale degree related to chord root is represented on the Circle of Fifths pie chart as different colors. You can use these corresponding
                                    colors to determine which note is responsible for which sentiment. Notes on the right side of the circle tend to feel brighter and more positive,
                                    where those on the left feel darker and negative. Notice the sentiments for each note flip between those associated with Major or Minor chords
                                    as you change chord qualities. The ability to change the colors will be added in a future update.
                                </p>
                            </td></tr></table>
                </div>
                <ScrollTopButton />
            </p>
        );
    };

    const KeyCenter = () => {
        return (
            <p>
                <div ref={keyCenterRef}>
                    <br></br>
                    <p style={{ fontSize: '1.2dvw' }}>6. Key-Center</p>

                    <table style={{ width: '95%', padding: '1dvw', borderCollapse: 'collapse' }} >
                        <tr style={{ borderBottom: '1px dashed #333' }}>
                            <td className='userguide' width={'30%'} align='left'
                                style={{
                                    padding: '1dvw',
                                    backgroundColor: '#000',
                                    margin: '1dvw 1dvw 1dvw 1dvw',
                                    verticalAlign: 'top',
                                    fontSize: '1dvw',
                                    fontStyle: 'normal',
                                }}>
                                <p>
                                    The percieved Key of a chord progression is not yet automatically tracked but will be in a future release. For now you may lock to a key center
                                    by clicking a piano key and unlock by clicking the same key. There is a special key center sentiments palette that will be selected upon locking to
                                    a key center. When in key center locked mode you'll notice you can play chords that have no root. This is a great way to simulate the
                                    emotions evoked by chord progressions over time.
                                </p>
                            </td></tr></table>
                </div>
                <ScrollTopButton />
            </p>
        );
    };

    const ChordChart = () => {
        return (
            <p>
                <div ref={chordChartRef}>
                    <br></br>
                    <p style={{ fontSize: '1.2dvw' }}>7. Chord Chart</p>

                    <table style={{ width: '95%', padding: '1dvw', borderCollapse: 'collapse' }} >
                        <tr style={{ borderBottom: '1px dashed #333' }}>
                            <td className='userguide' width={'30%'} align='left'
                                style={{
                                    padding: '1dvw',
                                    backgroundColor: '#000',
                                    margin: '1dvw 1dvw 1dvw 1dvw',
                                    verticalAlign: 'top',
                                    fontSize: '1dvw',
                                    fontStyle: 'normal',
                                }}>
                                <p>
                                    You may build chord charts simply by playing chords and then clicking on their name or hitting the enter key to add them to the chart.
                                    The arrow keys will navigate between the chords. Toggle the Sound On to hear the chords on the chord chart play. Setting the tempo of the chart will change
                                    the speed of the Arpeggiation when it is enabled.
                                </p>
                            </td></tr></table>
                </div>
                <ScrollTopButton />
            </p>
        );
    };


    return (
        <>
            <span onClick={openPopup} title='Show User Guide'
                style={{
                    border: 'solid',
                    verticalAlign: '-0.4dvw',
                    cursor: 'pointer',
                    borderRadius: '0dvw',
                    borderColor: 'black',
                    backgroundColor: 'black',
                    color: 'black',
                    fontSize: '1.6dvw',
                    width: '2dvw'
                }}>
                {'\u00a0'}
                {<img src="/images/HelpContents/infoicon.png" alt="" style={{ width: '1.4dvw', verticalAlign: '-0.4dvw' }} />}
                {'\u00a0'}
                {/* {'\u00a0\u2139\u00a0'} */}
            </span>

            {isOpen && (
                <div ref={contentRef} style={{
                    position: 'absolute', top: '5%', left: '10%', right: '10%', bottom: '10%', backgroundColor: '#1a1a1a', padding: '20px', color: '#fff', border: 'groove', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', overflowY: 'auto', zIndex: 1000,
                    maxHeight: '90vh'
                }}>
                    {/* <div id="topofpage" style={{ textAlign: 'center' }}>
                        <span onClick={closePopup} title='Hide User Guide' style={{ textAlign: 'center', border: 'groove', verticalAlign: '-0.0dvw', cursor: 'pointer', borderRadius: '0.4dvw', padding: '0.1dvw', backgroundColor: 'black', color: 'rgb(220,220,220)', fontSize: '1dvw' }}>
                            {'\u00a0Close\u00a0\u2716'}
                        </span></div> */}
                    {'\u00a0'}
                    {<img src="/images/HelpContents/infoicon.png" alt="" style={{ width: '2dvw', verticalAlign: '-0.4dvw' }} />}
                    {'\u00a0'}
                    <span style={{ fontSize: '1.7dvw', fontWeight: '700', color: 'rgb(220, 220, 220)' }}>{'\u00a0'}USER GUIDE | </span>
                    <span className='title-text' style={{ fontSize: '1.9dvw', fontWeight: '700' }}>sentisonics</span>
                    <span className='title-text' style={{ fontSize: '0.7dvw' }}> BETA</span>{'\u00a0'}

                    <table><tr><td width={'25%'} style={{ verticalAlign: 'top' }}>
                        <table style={{ fontSize: '1.2dvw', color: '#fff', padding: '1dvw' }}>
                            <span id="tableofcontents">
                                <tr>
                                    <td style={{ textAlign: 'right' }}>1.{'\u00a0'}</td>
                                    <td><a onClick={() => scrollToRef(gettingStartedRef)} style={{ cursor: 'pointer' }}>Getting Started</a></td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>2.{'\u00a0'}</td>
                                    <td><a onClick={() => scrollToRef(pianoDisplayRef)} style={{ cursor: 'pointer' }}>Piano Display</a></td></tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>3.{'\u00a0'}</td>
                                    <td><a onClick={() => scrollToRef(chordSymbolRef)} style={{ cursor: 'pointer' }}>Chord Symbol</a>
                                        <br></br><span style={{ fontSize: '1.1dvw' }}>a.{'\u00a0'} Root Perception
                                            <br></br>b.{'\u00a0'} Simultaneous Roots
                                            <br></br>c.{'\u00a0'} Root Influencers
                                            <br></br>d.{'\u00a0'} Limitless Harmony
                                            <br></br>e.{'\u00a0'} Scales<br></br></span>
                                    </td></tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>4.{'\u00a0'}</td>
                                    <td><a onClick={() => scrollToRef(sentimentRef)} style={{ cursor: 'pointer' }}>Sentiment</a></td></tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>5.{'\u00a0'}</td>
                                    <td><a onClick={() => scrollToRef(circleOfFifthsRef)} style={{ cursor: 'pointer' }}>Circle Of Fifths</a></td></tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>6.{'\u00a0'}</td>
                                    <td><a onClick={() => scrollToRef(keyCenterRef)} style={{ cursor: 'pointer' }}>Key-Center</a></td></tr>
                                <tr>
                                    <td style={{ textAlign: 'right' }}>7.{'\u00a0'}</td>
                                    <td><a onClick={() => scrollToRef(chordChartRef)} style={{ cursor: 'pointer' }}>Chord Chart</a></td></tr>
                            </span>
                        </table>
                    </td><td width={'75%'} style={{ textAlign: 'left', verticalAlign: 'top' }}>
                            <div style={{ textAlign: 'center' }}>
                                <img
                                    src="/images/HelpContents/appscreenshot1500x796.jpg"
                                    useMap="#image-map"
                                    alt="App Screenshot"
                                    style={{ maxWidth: '1500px', width: '93%', height: 'auto', border: 'groove', margin: '1dvw' }}
                                />

                                <map name="image-map">
                                    <area
                                        alt="Key Center"
                                        title="Key Center"
                                        coords="35,128,495,645"
                                        shape="rect"
                                        onClick={() => scrollToRef(keyCenterRef)}
                                    />
                                    <area
                                        alt="Chord Symbol"
                                        title="Chord Symbol"
                                        coords="165,28,355,330"
                                        shape="rect"
                                        onClick={() => scrollToRef(chordSymbolRef)}
                                    />
                                    <area
                                        alt="Sentiment"
                                        title="Sentiment"
                                        coords="1365,100,2000,1190"
                                        shape="rect"
                                        onClick={() => scrollToRef(sentimentRef)}
                                    />
                                    {/* Define more areas for other sections */}
                                </map>
                            </div>

                        </td></tr></table>

                    <div style={{ fontSize: '1dvw', color: 'rgb(220, 220, 220)' }}>
                        <GettingStarted />
                        <PianoDisplay />
                        <ChordSymbol />
                        <Sentiment />
                        <CircleOfFifths />
                        <KeyCenter />
                        <ChordChart />
                    </div>

                    {isOpen && (
                        <p style={{ textAlign: 'left' }}>
                            <span onClick={closePopup} title='Hide User Guide' style={{ textAlign: 'center', border: 'groove', verticalAlign: '-0.0dvw', cursor: 'pointer', borderRadius: '0.4dvw', padding: '0.1dvw', backgroundColor: 'black', color: 'rgb(220,220,220)', fontSize: '1dvw' }}>
                                {'\u00a0Close\u00a0\u2716'}
                            </span>
                        </p>
                    )}
                </div>
            )}
            {/* Background overlay to emphasize the popup */}
            {isOpen && (
                <div onClick={closePopup} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 999 }}></div>
            )}
        </>
    );
};

export default HelpPopup;