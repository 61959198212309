

import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import Key from './components/Key';
// import PCDisplay from './components/PCDisplay';
import { signOut } from 'firebase/auth';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import * as Tone from 'tone';
import ColorKey from './components/ColorKey';
// import Bar from './components/Bar';
import { auth, firestore } from './firebase';
import { getSpellings, midiToName } from './utils';
import Modal from 'react-modal';
import HelpPopup from './components/HelpPopup';
// import { Sampler, loaded } from 'tone';
// import sentisonicslogo from '.images/sentisonics-logo.jpg';

Modal.setAppElement('#root'); // matches root element in index.html file


// Comment out the local server for production, online server for dev:
export const server = 'https://sentisonics.pythonanywhere.com/sentiserver'
// export const server = '/sentiserver'
// const server = process.env.NODE_ENV === "development" ? "/sentiserver" : "http://localhost:5000/sentiserver";
// fetch("http://localhost:5000/sentiserver", {...}) // Use to test build



// Pie rotation
const rotation = -1.832; // -1.832 Root & 5th centered top, -1.573 Root top, -0.26 old
const gravityThreshold = 20

function getPieSlicePath(index: number, total: number): string {
  // To rotate counterclockwise left three slices for horizon display option
  // const sliceAngle = 2 * Math.PI / total;
  // const rotation = -3 * sliceAngle; // Rotate counter-clockwise by 3 slices

  // const startAngle = ((index - 0.5) / total) * 2 * Math.PI + rotation;
  // const endAngle = ((index + 0.5) / total) * 2 * Math.PI + rotation;
  const startAngle = ((index - 0.5) / total) * 2 * Math.PI + rotation;
  const endAngle = ((index + 0.5) / total) * 2 * Math.PI + rotation;

  const largeArcFlag = endAngle - startAngle <= Math.PI ? 0 : 1;

  const radius = 20; // Doubled the radius

  const startX = radius * Math.cos(startAngle);
  const startY = radius * Math.sin(startAngle);
  const endX = radius * Math.cos(endAngle);
  const endY = radius * Math.sin(endAngle);

  return `M 0 0 L ${startX} ${startY} A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY} Z`;
}



// export const defaultEmoClassLabelsMajor = ['', '', 'Hope', 'Peace', 'Brightness', 'Yearning', 'Enchantment', 'Eeriness', 'Mysticism', 'Fortitude', 'Anticipation', 'Suspense']
// export const defaultEmoClassLabelsMinor = ['', '', 'Longing', 'Bewitchment', 'Rebellion', 'Despair', 'Dread', 'Gloom', 'Apprehension', 'Sadness', 'Pensiveness', 'Wistfulness']


// Emotion Descriptors:

// root Major: Existence and Prominence Major root and 5th
// root Minor: Perseverance and Solemnity Minor root and 5th

// Perf 5 Major: Stability, Assertiveness, Permanence, Vigor, Fervor, Ardor, Zeal, Avidity, Eminence, Ardency, Potency, Salience, Saliency or Prominence
// Perf 5 Minor: Solemnity, Seriousness

// Maj 2: Ascension for Hope
// Maj 6: Comfort, Calm, Reassurance, Hope for Peace

// Mystery or Enigmatism or Suspicion for Suspicion
// Hopelessness for Despair, and Depression, Darkness, Sunken-ness for Worry

// Min 2: Discomfort, Aggravation, Unsettled, Unease, Disturbing, Anxiety, Sinisterness for Eeriness
// Foreboding for Dread
// Malevolence or Dismay or Discomfort or Haunt or Disconcertment for Eeriness

// Perf 4: Aspiration, Expectation, Elevation, Ascension, Transcendence, Suspension, Suspense, Postponement, Weightlessness, Respite, Levitation, Stasis, Flotation, Buoyancy
// Min 7: Stasis, Anticipation
// Min 3: Defiance or Rebellion for Fortitude
// Min 2: Discomfort, Aggravation, Unsettled, Unease, Disturbing, Anxiety, Sinisterness for Eeriness
// Foreboding for Dread
// Maj 3: Optimism, Contentment, Gratification, Pleasure, Fulfillment, Triumph, Joy, Sanctuary, Jubilation, Positivity, Brightness, Radiance, Calmness, Comfort, Composure
// Maj 3 in minor: Distress, Discomfort, Aggitation
// try Vigor
// try Bewilderment


export const defaultEmoClassLabelsMajor = ['', '', 'Hope', 'Reassurance', 'Positivity', 'Yearning', 'Enchantment', 'Unease', 'Eeriness', 'Defiance', 'Anticipation', 'Expectation']
export const defaultEmoClassLabelsMinor = ['', '', 'Longing', 'Bewitchment', 'Discomfort', 'Despair', 'Dread', 'Gloom', 'Worry', 'Sadness', 'Pensiveness', 'Wistfulness']

const defaultEmoClassLabelsKeyMajor = ['', 'Confidence', 'Hope', 'Reassurance', 'Tranquility', 'Passion', 'Mystery', 'Awe', 'Lament', 'Bravery', 'Nobility', 'Fleetingness']
const defaultEmoClassLabelsKeyMinor = ['', 'Gravitas', 'Longing', 'Bewitchment', 'Alarm', 'Desire', 'Intrigue', 'Concern', 'Worry', 'Sadness', 'Pensiveness', 'Wistfulness']

const defaultEmoClassLabelsAdjMajor = ['', '', 'Hopeful', 'Reassured', 'Content', 'Romantic', 'Enchanted', 'Uneasey', 'Eerie', 'Rebellious', 'Anticipatory', 'Weightless']
const defaultEmoClassLabelsAdjMinor = ['', '', 'Regretful', 'Bewitched', 'Aggravated', 'Hopeless', 'Dreadful', 'Gloomy', 'Worried', 'Sad', 'Pensive', 'Elevated']

const defaultEmoClassLabelsSent2Major = ['', '', 'Encouragement', 'Tranquility', 'Gratefulness', 'Romance', 'Adventure', 'Disgust', 'Mystery', 'Rebellion', 'Expectation', 'Weightlessness']
const defaultEmoClassLabelsSent2Minor = ['', '', 'Wistfulness', 'Spookiness', 'Annoyance', 'Devastation', 'Evil', 'Brokenness', 'Apprehension', 'Despair', 'Co-existance', 'Indifference']

const defaultEmoClassLabelsImgMajor = ['', '', 'Bright Horizon', 'Warm Light', 'Radiance', 'Bittersweet Tears', 'Enchanting Glow', 'Mystical Fog', 'Supernatural Glow', 'Brave Warrior', 'Slow Motion', 'Zero Gravity']
const defaultEmoClassLabelsImgMinor = ['', '', 'Sad Tears', 'Magic', 'Pain', 'Dark Horizon', 'Evil Spirit', 'Looming Darkness', 'Eerie Clouds', 'Shadow', 'Friend', 'Elevation']

const defaultEmoClassLabelsCharMajor = ['The Creator', 'The Father', 'The Brother', 'The Mother', 'The Best Friend', 'The Lover', 'The Explorer', 'The Bully', 'The Wizard', 'The Rebel', 'The Prophet', 'The Pioneer']
const defaultEmoClassLabelsCharMinor = ['The Ruler', 'The Architect', 'The Dreamer', 'The Mystic', 'The Pest', 'The Mourner', 'The Villian', 'The Widow', 'The Worrywort', 'The Pessimist', 'The Philosopher', 'The Enlightened']

const defaultEmoClassLabelsSymMajor = ['', '', 'Sunrise', 'Fireplace', 'Home', 'Sweetheart', 'Fairy Dust', 'Knife', 'Magic Wand', 'Clenched Fist', 'Stopwatch', 'Light Bulb']
const defaultEmoClassLabelsSymMinor = ['', '', 'Tombstone', 'Ghost', 'Nails on Chalkboard', 'Storm Clouds', 'Witch\'s Brew', 'Pitch Darkness', 'Alien', 'Rain', 'Hand in Hand', 'Ladder']


interface Palette {
  label: string,
  majorLabels: string[],
  minorLabels: string[],
}

const defaultPalettes: Palette[] = [
  {
    label: "Chord Emotions",
    majorLabels: defaultEmoClassLabelsMajor,
    minorLabels: defaultEmoClassLabelsMinor,
  },
  {
    label: "Key Center Emotions",
    majorLabels: defaultEmoClassLabelsKeyMajor,
    minorLabels: defaultEmoClassLabelsKeyMinor,
  },
  {
    label: "More Chord Emotions",
    majorLabels: defaultEmoClassLabelsSent2Major,
    minorLabels: defaultEmoClassLabelsSent2Minor,
  },
  {
    label: "Imagery",
    majorLabels: defaultEmoClassLabelsImgMajor,
    minorLabels: defaultEmoClassLabelsImgMinor,
  },
  {
    label: "Characters",
    majorLabels: defaultEmoClassLabelsCharMajor,
    minorLabels: defaultEmoClassLabelsCharMinor,
  },
  {
    label: "Symbols",
    majorLabels: defaultEmoClassLabelsSymMajor,
    minorLabels: defaultEmoClassLabelsSymMinor,
  }
  // {
  //   label: "Emotions 1 (Adjectives)",
  //   majorLabels: defaultEmoClassLabelsAdjMajor,
  //   minorLabels: defaultEmoClassLabelsAdjMinor,
  // }
]

// NEW ADDITION ACRYLICODE
// ///////////////////////
// const defaultSongs = [
//   {
//     songTitle: "Demo Song",
//     composer: "Sentisonics",
//     timeSigBeats: 3,
//     timeSigDuration: 4,
//     chordChart: [
//       { name: "F∆7", voicing: [45, 56, 74], isSeparator: false },
//       { name: "G7", voicing: [30, 53, 71], isSeparator: false },
//     ]
//   },
//   {
//     songTitle: "Song Title",
//     composer: "Composer",
//     timeSigBeats: 4,
//     timeSigDuration: 4,
//     tempo: 120,
//     chordChart: [
//       { name: "/", voicing: [], isSeparator: true },
//     ]
//   }
// ]

// type ChordChartEntry = {
//   name: string;
//   voicing: number[];
//   isSeparator: boolean;
// };
// type Song = {
//   songTitle: string,
//   composer: string,
//   timeSigBeats: number,
//   timeSigDuration: number,
//   chordChart: ChordChartEntry[]
// };


// MAIN COMPONENT
const App: React.FC = () => {
  const [activeNotes, setActiveNotes] = useState<number[]>([]);
  const [heldNotes, setHeldNotes] = useState<number[]>([]);
  const [toggledNotes, setToggledNotes] = useState<number[]>([]);
  const activeNotesRef = useRef(activeNotes);
  const toggledNotesRef = useRef(toggledNotes);
  const heldNotesRef = useRef(heldNotes);
  const [sustain, setSustain] = useState<boolean>(false);
  const sustainRef = useRef(sustain);
  const [midiAccess, setMidiAccess] = useState<WebMidi.MIDIAccess | null>(null);
  const [selectedInputId, setSelectedInputId] = useState<string | null>(null);
  const [midiNotes, setMidiNotes] = useState<number[]>([]);
  const [keyflash, setKeyflash] = useState<number[]>([]);
  // const [labelFlashes, setLabelFlashes] = useState({});
  const [labelFlashes, setLabelFlashes] = useState<Record<number, boolean>>({});
  // const [pcLabelFlashes, setPcLabelFlashes] = useState({});
  const [pcLabelFlashes, setPcLabelFlashes] = useState<Record<number, boolean>>({
    0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false, 11: false
  });
  const [keyCenter, setKeyCenter] = useState<number>(0);
  const [mode, setMode] = useState<number>(0);
  const [modes, setModes] = useState<number[]>([]);
  const [pcWeightsSorted, setPcWeightsSorted] = useState<number[]>([]);
  const [pcSorted, setPcSorted] = useState<number[]>([]);
  const [activeEmoLabels, setActiveEmoLabels] = useState<string[]>([]);
  const [activeEmoLabelsNextRoot, setActiveEmoLabelsNextRoot] = useState<string[]>([]);
  const [emoClassLabelsNextRoot, setEmoClassLabelsNextRoot] = useState<string[]>([]);
  const rootNoteRef = useRef<number>(0); // default value
  const blackKeyOffsetsOneOctave = [10, 30, 50, 70, 90];
  const [soundOn, setSoundOn] = useState<boolean>(false);
  const samplerRef = useRef<Tone.Sampler | null>(null);
  const [audioInitialized, setAudioInitialized] = useState<boolean>(false);
  // const audioInitializedRef = useRef(audioInitialized);
  const [shouldPlaySound, setShouldPlaySound] = useState(false);
  const shouldPlaySoundRef = useRef(false);
  const [octaveOffset, setOctaveOffset] = useState<number>(0);
  const [lockedNote, setLockedNote] = useState<number | null>(null); // null when no note is locked
  const [lockedMode, setLockedMode] = useState<number | null>(0); // 0 for Major as default
  const [keyNameDisplay, setKeyNameDisplay] = useState('');
  const [isScale, setIsScale] = useState<boolean>(false);
  const [scaleCode, setScaleCode] = useState<string>("");
  const [scaleUrl, setScaleUrl] = useState<string>("");
  const [activePcs, setActivePcs] = useState<number[]>([]);
  const [isArpeggiateOn, setIsArpeggiateOn] = useState<boolean>(false);
  const isInputtingTextRef = useRef(false);


  const appStyle = {
    maxHeight: '2000px', // Adjust the value as needed
    overflow: 'auto', // Adds scrollbars if content exceeds max height
  };


  const handleInputFocus = () => { isInputtingTextRef.current = true; };
  const handleInputBlur = () => { isInputtingTextRef.current = false; };

  // NEW ADDITION ACRYLICODE
  // ///////////////////
  // BEGIN Chord Chart handlers
  // const [chordChart, setChordChart] = useState<ChordChartEntry[]>([]);
  // const [userSongs, setUserSongs] = useState<Song[]>([]);
  // const [allSongs, setAllSongs] = useState<Song[]>(defaultSongs);
  // const [selectedSong, setSelectedSong] = useState<Song>(defaultSongs[0]);

  // BEGIN Chord Chart handlers
  const [chordChart, setChordChart] = useState<{
    name: string,
    voicing: number[],
    isSeparator: boolean
  }[]>([]);

  // const [chordChart, setChordChart] = useState<{ name: string, voicing: number[], isSeparator: boolean, isRehearsalMark: boolean, isTimeSig: boolean}[]>([]);

  // const [chordChart, setChordChart] = useState<{
  //   type: string // either 'chord', 'separator', 'marker', 'timesig', 'keysig', or 'systembreak'
  //   name: string, // chord (quality) m9b5, ∆13#11, etc., SEPARATORS.barline etc., marker 
  //   root: number, // 0-11 pitch class
  //   voicing: number[], // array of midi note numbers
  //   keySig: number[], // [0-11 pitch class, 0 for major 1 for minor]
  //   altBass: [boolean, number], // is alternate bass note, pitch class
  // }[]>([]);

  const [chartLength, setChartLength] = useState<number>(0);
  const [songTitle, setSongTitle] = useState({ title: "Song Title", isEditable: false });
  const [songTitleLength, setSongTitleLength] = useState<number>(0);
  const [composer, setComposer] = useState({ name: "Composer", isEditable: false });
  const [tempo, setTempo] = useState({ value: "120", isEditable: false });
  const [timeSigBeats, setTimeSigBeats] = useState(4);
  const [timeSigDuration, setTimeSigDuration] = useState(4);
  const beatOptions = [2, 3, 4, 5, 6, 7, 9, 12];
  const durationOptions = [2, 4, 8];
  const [selectedChordIndex, setSelectedChordIndex] = useState<number | null>(null);
  const selectedChordIndexRef = useRef(selectedChordIndex);
  const [playSongOn, setPlaySongOn] = useState<boolean>(false);
  const playSongOnRef = useRef(playSongOn);
  const [currentPlaySongIndex, setCurrentPlaySongIndex] = useState<number | null>(null);
  type ChordChartEntry = { name: string; voicing: number[]; isSeparator: boolean; }; // NEW REMOVAL ACRYLICODE
  const [chordChartHistory, setChordChartHistory] = useState<ChordChartEntry[][]>([]);
  const [currentHistoryIndex, setCurrentHistoryIndex] = useState(0);
  const nextSelectedIndexRef = useRef<number | null>(null);





  const SEPARATORS = {
    // spacer: "\u00A0", // ⬚ \u00A0\u00A0\u00A0
    // spacer: "⬚", // ⬚ \u00A0\u00A0\u00A0
    barline: "|", // "𝄀"
    doubleBarline: "𝄁",
    repeatStart: "𝄆",
    repeatEnd: "𝄇",
    repeatMeasure: "𝄎",
    doubleRepeatMeasure: "𝄏",
    slash: "/" // "𝄍"
  };


  const MARKERS = {
    a: "Ⓐ",
    b: "Ⓑ",
    c: "Ⓒ",
    d: "Ⓓ",
    v: "Ⓥ",
    i: "Ⓘ",
    s: "𝄋",
    q: "𝄌",
    f: "𝄐"
  }


  // Tempo Slider
  // type RangeChangeEvent = React.ChangeEvent<HTMLInputElement>;
  // const [tempoSliderValue, onChange] = useState<number>(1);
  // useEffect(() => {
  //   const ele = document.querySelector('.buble') as HTMLElement | null;
  //   if (ele) {
  //     ele.style.bottom = `${Number(tempoSliderValue / 4)}px`;
  //   }
  // }, [tempoSliderValue]); // Added value as a dependency
  // const handleSliderChange = (event: RangeChangeEvent) => {
  //   onChange(Number(event.target.value));
  // };


  // State for managing tempo value and slider visibility
  // const [tempo, setTempo] = useState({ value: 120, isEditable: false }); ABOVE
  const [showSlider, setShowSlider] = useState(false);

  // const handleTempoChange = (event: React.ChangeEvent<HTMLInputElement>) => { BELOW
  //   setTempo({ ...tempo, value: event.target.value });
  // };

  const toggleEditTempo = () => { // Replaced the BELOW
    setShowSlider(!showSlider);
    // Additional logic if needed when toggling
  };

  // Update tempo value from slider
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempo({ ...tempo, value: event.target.value });
  };


  useEffect(() => {
    selectedChordIndexRef.current = selectedChordIndex;
  }, [selectedChordIndex]);

  useEffect(() => {
    if (selectedChordIndex !== null && selectedChordIndex >= 0 && selectedChordIndex <= chordChart.length) {
      const selectedChord = chordChart[selectedChordIndex];
      if (selectedChord && !selectedChord.isSeparator) {
        releaseToggledNotes();
        setToggledNotes(selectedChord.voicing);
        toggledNotesRef.current = selectedChord.voicing;
        playChartChord();
      }
    } else {
      releaseToggledNotes();
      setToggledNotes([]);
    }
  }, [selectedChordIndex]);


  useEffect(() => {
    if (nextSelectedIndexRef.current !== null) {
      setSelectedChordIndex(nextSelectedIndexRef.current);
      nextSelectedIndexRef.current = null; // Reset the ref
    }
  }, [chordChart]);



  // Undo Redo functions
  useEffect(() => {
    // If the current state is different from the latest in history, update history
    if (chordChart !== chordChartHistory[currentHistoryIndex]) {
      const updatedHistory = [...chordChartHistory.slice(0, currentHistoryIndex + 1), chordChart];
      setChordChartHistory(updatedHistory);
      setCurrentHistoryIndex(updatedHistory.length - 1);
    }
  }, [chordChart]);
  const undo = () => {
    if (currentHistoryIndex > 0) {
      setCurrentHistoryIndex(currentHistoryIndex - 1);
      setChordChart(chordChartHistory[currentHistoryIndex - 1]);
    }
  };
  const redo = () => {
    if (currentHistoryIndex < chordChartHistory.length - 1) {
      setCurrentHistoryIndex(currentHistoryIndex + 1);
      setChordChart(chordChartHistory[currentHistoryIndex + 1]);
    }
  };



  useEffect(() => {
    playSongOnRef.current = playSongOn;
  }, [playSongOn]);




  // const playSelectedChord = (chordIndex: number) => {
  //   const chord = chordChart[chordIndex];
  //   if (!chord.isSeparator) {
  //     releaseToggledNotes();
  //     setToggledNotes(chord.voicing);
  //     toggledNotesRef.current = chord.voicing;
  //     playChartChord();
  //   }
  // };

  const handleChordSelection = (index: React.SetStateAction<number | null>) => {
    // Determine the actual index value
    const actualIndex = typeof index === 'function' ? index(selectedChordIndex) : index;
    if (actualIndex !== null && actualIndex >= 0 && actualIndex < chordChart.length) {
      const selectedChord = chordChart[actualIndex];
      if (selectedChord && !selectedChord.isSeparator) {
        releaseToggledNotes();
        setToggledNotes(selectedChord.voicing);
        if (selectedChordIndexRef.current !== actualIndex) {
          setSelectedChordIndex(actualIndex);
        } else {
          playChartChord();
        }
      }
    }
  };


  // const handleChordNavigation = (direction: string) => {
  //   // Logic to determine the next index based on the direction
  //   const newIndex = calculateNewIndex(direction, selectedChordIndex, chordChart);
  //   setSelectedChordIndex(newIndex);
  // };



  // const toggleChordSelection = (index: number) => {
  //   setSelectedChordIndex(prevIndex => prevIndex === index ? null : index);
  // };

  const handleGlobalClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement; // Type assertion
    console.log('GLOBAL CLICK EVENT!! selectedChordIndexRef.current', selectedChordIndexRef.current)
    if (!target.matches('.chord') &&
      !target.matches('.delete-chord') &&
      !target.matches('.color-key') &&
      !target.matches('.mode-select') &&
      !target.closest('[data-actionable="true"]')
    ) {
      if (selectedChordIndexRef.current != null) {
        setSelectedChordIndex(null);
        releaseToggledNotes();
        setToggledNotes([]);
      }
    }
  };

  const chartNavigation = (direction: string) => {
    if (!playSongOnRef.current) {
      setSelectedChordIndex(prevIndex => {
        const defaultIndexForBackward = 0;
        const defaultIndexForForward = chordChart.length - 1;

        let newIndex = prevIndex !== null
          ? (direction === "forward"
            ? Math.min(prevIndex + 1, chordChart.length - 1)
            : direction === "jump-forward"
              ? Math.min(prevIndex + 6, chordChart.length - 1)
              : direction === "jump-backward"
                ? Math.max(prevIndex - 6, 0)
                : Math.max(prevIndex - 1, 0))
          : (direction === "forward" || direction === "jump-forward"
            ? defaultIndexForForward
            : defaultIndexForBackward);


        // The logic to play the chord will be handled by the useEffect
        return newIndex;
      });
    }
  };



  useEffect(() => {
    document.addEventListener('click', handleGlobalClick);
    return () => {
      document.removeEventListener('click', handleGlobalClick);
    };
  }, []);


  // const toggleChordSelection = (index: number) => {
  //   setSelectedChordIndex(prevIndex => {
  //     if (prevIndex === index) {
  //       // Deselect chord
  //       releaseToggledNotes();
  //       setToggledNotes([]); // Reset toggledNotes on deselection
  //       return null;
  //     } else {
  //       const selectedEntry = chordChart[index];
  //       if (!selectedEntry.isSeparator) {
  //         // Select chord and update toggledNotes with its voicing
  //         releaseToggledNotes();
  //         const selectedChord = chordChart[index];
  //         setToggledNotes(selectedChord.voicing);
  //         toggledNotesRef.current = selectedChord.voicing; // Synchronously update the ref

  //         playChartChord();
  //       }
  //       return index;
  //     }
  //   });
  // };


  function playChartChord() {
    // let sortedMidiNotes = toggledNotesRef.current.sort((a, b) => a - b);
    let uniqueMidiNotes = Array.from(new Set(toggledNotesRef.current));
    let sortedMidiNotes = uniqueMidiNotes.sort((a, b) => a - b);

    let arpSpeed = 10000 / parseInt(tempo.value, 10) / (1 + sortedMidiNotes.length / 50); // larger chords arpeggiate faster
    sortedMidiNotes.forEach((note, index) => {
      if (isArpeggiateOn) {
        setTimeout(() => {
          if (shouldPlaySoundRef.current && samplerRef.current) {
            samplerRef.current.triggerAttack(Tone.Midi(note).toNote(), undefined, 0.65);
          }
        }, index * arpSpeed); // 100ms interval between notes
      } else {
        if (shouldPlaySoundRef.current && samplerRef.current) {
          samplerRef.current.triggerAttack(Tone.Midi(note).toNote(), undefined, 0.65);
        }
      }
    });
  }


  function releaseToggledNotes() {
    if (shouldPlaySoundRef.current && samplerRef.current) {
      toggledNotesRef.current.forEach(note => {
        samplerRef.current!.triggerRelease(Tone.Midi(note).toNote());
      });
    }
  }

  // WORKS!!! But adding new method below:
  const addEntryToChart = (entryName: string, separator: boolean) => {
    const allNotes = !separator ? [...activeNotes, ...heldNotes, ...toggledNotes] : [];
    const newEntry = { name: entryName, voicing: entryName === '\u2001' ? [] : allNotes, isSeparator: separator };
    const isNoChordSelected = selectedChordIndex === null;

    if (!separator) { // if entry is a chord
      const lastEntry = chordChart[chordChart.length - 1];
      const shouldAddBarline = isNoChordSelected // if no chord is selected check the type of the last chart entry
        ? (chordChart.length > 0 && (!lastEntry.isSeparator || lastEntry.name !== SEPARATORS.barline))
        : (selectedChordIndex === chordChart.length - 1 &&
          (!chordChart[selectedChordIndex].isSeparator || chordChart[selectedChordIndex].name !== SEPARATORS.barline));

      const insertionIndex = isNoChordSelected ? chordChart.length : selectedChordIndex + 1;

      setChordChart(prevChart => [
        ...prevChart.slice(0, insertionIndex),
        ...(shouldAddBarline ? [{ name: SEPARATORS.barline, voicing: [], isSeparator: true }] : []),
        newEntry,
        // ...[{ name: '\u2001', voicing: [], isSeparator: false }], // new addition, skipped
        ...prevChart.slice(insertionIndex)
      ]);

      nextSelectedIndexRef.current = insertionIndex + (shouldAddBarline ? 1 : 0);
    } else {
      // If adding a separator
      const insertionIndex = isNoChordSelected ? chordChart.length : selectedChordIndex + 1;

      setChordChart(prevChart => [
        ...prevChart.slice(0, insertionIndex),
        newEntry,
        ...prevChart.slice(insertionIndex)
      ]);

      nextSelectedIndexRef.current = insertionIndex;
    }
  };


  // NEW CHATGPT Experiement:
  // const addEntryToChart = (entryName: string, separator: boolean) => {
  //   const allNotes = !separator ? [...activeNotes, ...heldNotes, ...toggledNotes] : [];
  //   const newEntry = { name: entryName, voicing: allNotes, isSeparator: separator };
  //   const isNoChordSelected = selectedChordIndex === null;

  //   // Determine the insertion or replacement index
  //   const insertionIndex = isNoChordSelected ? chordChart.length : selectedChordIndex;
  //   const isAtEndOfChart = insertionIndex === chordChart.length - 1 || isNoChordSelected;

  //   // Determine if the selected chord is a blank chord
  //   const isSelectedChordBlank = !isNoChordSelected && chordChart[selectedChordIndex]?.name === '\u2001';

  //   if (!separator) { // if entry is a chord
  //     let updatedChart = [...chordChart];
  //     if (!isNoChordSelected && !chordChart[selectedChordIndex].isSeparator) {
  //       // Replace the currently selected chord
  //       updatedChart[insertionIndex] = newEntry;
  //     } else {
  //       // Insert the new chord at the insertionIndex
  //       updatedChart.splice(insertionIndex + 1, 0, newEntry);
  //     }

  //     // Add a blank chord entry at the end if needed
  //     if (isAtEndOfChart) {
  //       updatedChart.push({ name: '\u2001', voicing: [], isSeparator: false });
  //     }

  //     setChordChart(updatedChart);
  //     // Update the selected chord index
  //     nextSelectedIndexRef.current = isSelectedChordBlank && isAtEndOfChart ? updatedChart.length - 1 : insertionIndex;

  //   } else {
  //     // If adding a separator, simply insert it at the next position
  //     setChordChart(prevChart => [
  //       ...prevChart.slice(0, insertionIndex + 1),
  //       newEntry,
  //       ...prevChart.slice(insertionIndex + 1)
  //     ]);
  //     nextSelectedIndexRef.current = insertionIndex + 1;
  //   }
  // };








  // const addSeparatorToChart = (separatorType: string) => {
  //   const newSeparatorEntry = { name: separatorType, voicing: [], isSeparator: true };

  //   if (selectedChordIndex === null) {
  //     const lastEntry = chordChart[chordChart.length - 1];
  //     const shouldAddBarline = chordChart.length > 0 && (!lastEntry.isSeparator || lastEntry.name !== separatorType);

  //     setChordChart(prevChart => [
  //       ...prevChart,
  //       ...(shouldAddBarline ? [newSeparatorEntry] : [])
  //     ]);

  //     if (shouldAddBarline) {
  //       selectedChordIndexRef.current; // Select the new separator
  //     }
  //   } else {
  //     const selectedEntry = chordChart[selectedChordIndex];
  //     const shouldAddBarline = chordChart.length > 0 &&
  //       (!selectedEntry.isSeparator ||
  //         (selectedEntry.isSeparator && selectedEntry.name !== separatorType));

  //     if (shouldAddBarline) {
  //       const insertionIndex = selectedChordIndex + 1;
  //       const updatedChart = [
  //         ...chordChart.slice(0, insertionIndex),
  //         newSeparatorEntry,
  //         ...chordChart.slice(insertionIndex)
  //       ];

  //       setChordChart(updatedChart);
  //       selectedChordIndexRef.current; // Select the new separator
  //     } else {
  //       // If the selected entry is the same type of separator, just select it
  //       return selectedChordIndexRef.current;
  //     }
  //   }
  // };



  const updateSeparator = (index: number, newSeparator: string) => {
    const updatedChart = chordChart.map((entry, idx) => {
      if (idx === index && entry.isSeparator) {
        return { ...entry, name: newSeparator };
      }
      return entry;
    });
    setChordChart(updatedChart);
    setSelectedChordIndex(index);
  };


  const spacer = "\u00A0" // options ⁘ ⁞ ⋮ ⋰ ⠿ ⠀ ⬚ ∴ ⋮ ▭ ⬜ ⎵ … ☐ □   ╎ ┆ ┊ ◌ ♢ ♭ ♯ ⸬ 𝄆 𝄄 𝄀 𝄁 𝄃 𝄇


  // let playTimeouts: NodeJS.Timeout[] = [];

  useEffect(() => {
    if (playSongOn) {
      playSong();
    } else {
      stopSong();
    }
  }, [playSongOn]); // Dependency array includes playSongOn



  const playIntervalRef = useRef<any>(null);

  const playSong = () => {
    let index = (selectedChordIndex !== null && selectedChordIndex < chordChart.length - 1) ? selectedChordIndex : 0;
    const tempoValue = parseInt(tempo.value, 10);
    if (isNaN(tempoValue) || tempoValue <= 0) {
      console.error("Invalid tempo value");
      return;
    }
    const interval = (60000 / tempoValue) * timeSigDuration; // Interval for one bar
    const advanceIndex = () => {
      index++;
      while (index < chordChart.length && chordChart[index].isSeparator) {
        index++; // Skip separators
      }
      if (index < chordChart.length) {
        setSelectedChordIndex(index);
      } else {
        clearInterval(playIntervalRef.current);
        playIntervalRef.current = null;
        setCurrentPlaySongIndex(null);
        setPlaySongOn(false);
        setSelectedChordIndex(null);
      }
    };
    if (index < chordChart.length && !chordChart[index].isSeparator) {
      if (selectedChordIndex !== index) {
        setSelectedChordIndex(index);
      } else {
        playChartChord();
      }
    }
    playIntervalRef.current = setInterval(advanceIndex, interval);
    setCurrentPlaySongIndex(index);
  };




  const stopSong = () => {
    clearInterval(playIntervalRef.current);
    playIntervalRef.current = null; // Reset the ref
    setCurrentPlaySongIndex(null);
    setPlaySongOn(false);
  };


  const handlePlaySongToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setPlaySongOn(prevState => !prevState); // Just toggle the state
    // if (currentPlaySongIndex === null) {
    //   playSong();
    //   setPlaySongOn(true);
    // } else {
    //   stopSong();
    // }
  };



  // const addChordToChart = (chordName: string) => {
  //   // Combine notes for voicing
  //   const allNotes = [...activeNotes, ...heldNotes, ...toggledNotes];

  //   if (selectedChordIndex !== null) {
  //     const shouldAddSpacer = chordChart.length > 0 && selectedChordIndex > 0 && chordChart[selectedChordIndex - 1].name !== spacer;
  //     // Insert a new chord after the selected chord with voicing
  //     const updatedChart = [
  //       ...chordChart.slice(0, selectedChordIndex + 1),
  //       ...(shouldAddSpacer ? [{ name: spacer, isEditable: false, voicing: [] }] : []),
  //       { name: chordName, isEditable: false, voicing: allNotes },
  //       ...chordChart.slice(selectedChordIndex + 1),
  //     ];
  //     setChordChart(updatedChart);
  //     setSelectedChordIndex(selectedChordIndex + (shouldAddSpacer ? 2 : 1));
  //   } else {
  //     const shouldAddSymbol = chartLength === 0 || chordChart[chordChart.length - 1].name !== spacer;
  //     // Add a new chord with voicing
  //     setChordChart(prevChart => [
  //       ...prevChart,
  //       ...(shouldAddSymbol ? [{ name: spacer, isEditable: false, voicing: [] }] : []),
  //       { name: chordName, isEditable: false, voicing: allNotes }
  //     ]);
  //   }
  // };


  // const addChordToChart = (chordName: string) => {
  //   if (selectedChordIndex !== null) { // Replace the selected chord
  //     const updatedChart = chordChart.map((chord, idx) => {
  //       if (idx === selectedChordIndex) {
  //         return { ...chord, name: chordName };
  //       }
  //       return chord;
  //     });
  //     setChordChart(updatedChart);
  //   } else {
  //     // Add " | " if chartLength is greater than 0, then add the new chord
  //     setChordChart(prevChart => [
  //       ...prevChart,
  //       ...(chartLength > 0 ? [{ name: " | ", isEditable: false }] : []),
  //       { name: chordName, isEditable: false }
  //     ]);
  //   }
  // };





  // const addChordToChart = (selectedChord: string) => {
  //   if (selectedChordIndex !== null) {
  //     // Replace the selected chord in the chart
  //     const updatedChart = chordChart.map((chord, idx) => {
  //       if (idx === selectedChordIndex) {
  //         return { name: selectedChord, isEditable: false };
  //       }
  //       return chord;
  //     });
  //     setChordChart(updatedChart);
  //     setSelectedChordIndex(null); // Reset the selection after replacement
  //   } else {
  //     // Add new chord to the chart
  //     setChordChart([...chordChart, { name: selectedChord, isEditable: false }]);
  //   }
  // };

  // const toggleChordSelection = (index: number, isSelecting: boolean) => {
  //   if (isSelecting) {
  //     // Selecting a chord
  //     setSelectedChordIndex(index);
  //   } else {
  //     // Deselecting (clicking outside or onBlur)
  //     if (selectedChordIndex === index) {
  //       setSelectedChordIndex(null);
  //     }
  //   }
  // };









  // const toggleChordSelection = (index: number, isSelecting: boolean) => {
  //   if (selectedChordIndex === index) {
  //     // If the same chord is clicked again, unselect it
  //     setSelectedChordIndex(null);
  //   } else {
  //     // Otherwise, select the new chord
  //     setSelectedChordIndex(index);
  //   }
  // };

  const deselectChord = () => {
    setSelectedChordIndex(null);
  };

  const deleteChordFromChart = (index: number) => {
    if (!playSongOnRef.current) {
      const updatedChart = chordChart.filter((_, idx) => idx !== index);
      setChordChart(updatedChart);

      if (updatedChart.length === 0) {
        // If all chords are deleted, reset the selection
        setSelectedChordIndex(null);
      } else if (index === chordChart.length - 1) {
        // If the last chord is deleted, select the new last chord
        setSelectedChordIndex(updatedChart.length - 1);
      } else if (index === 0) {
        // If the first chord is deleted, select the new first chord
        setSelectedChordIndex(index);
      } else if (index === selectedChordIndex) {
        // If the deleted chord was selected, move the selection to the previous index
        setSelectedChordIndex(index - 1);
      }
    }
  };

  const toggleEditTitle = () => {
    setSongTitle({ ...songTitle, isEditable: !songTitle.isEditable });
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSongTitle({ ...songTitle, title: event.target.value });
  };

  const toggleEditComposer = () => {
    setComposer({ ...composer, isEditable: !composer.isEditable });
  };

  const handleComposerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComposer({ ...composer, name: event.target.value });
  };

  // const toggleEditTempo = () => {
  //   setTempo({ ...tempo, isEditable: !tempo.isEditable });
  // };

  const handleTempoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempo({ ...tempo, value: event.target.value });
  };

  const MAX_LENGTH_TO_REDUCE = 800
  useEffect(() => {
    const currentChartLength = chordChart.reduce((acc, chord) => acc + chord.name.length, 0)
    const currentSongTitleLength = songTitle.title.length;
    setChartLength(Math.min(currentChartLength, MAX_LENGTH_TO_REDUCE));
    setSongTitleLength(songTitle.title.length)
    console.log("songTitleLength:", songTitleLength)
  }, [chordChart, songTitle]);


  const concatenateChartData = () => {
    let dataString = `${songTitle.title}, `;
    dataString += `by ${composer.name}\n`;
    dataString += `${timeSigBeats}/${timeSigDuration}`;
    dataString += " || ";
    // dataString += chordChart.map(chord => chord.name).join("  ");
    dataString += chordChart.map(chord => {
      return chord.name === spacer ? ` ${spacer} ` : chord.name;
    }).join("  ");
    dataString += "  ||";

    // dataString = convertToIrealPro(dataString); // overwrite, test irealpro export
    return dataString;
  };

  const copyChordChartToClipboard = async () => {
    const dataToCopy = concatenateChartData();
    try {
      await navigator.clipboard.writeText(dataToCopy);
      alert('Data copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  function convertToIrealPro(input: string): string {
    // Helper function to encode special characters to iRealPro format
    function encodeSpecialChars(str: string): string {
      return encodeURIComponent(str)
        .replace(/%20/g, ' ')
        .replace(/%2F/g, '/')
        .replace(/%2D/g, '-')
        .replace(/%5E/g, '^')
        .replace(/%7B/g, '{')
        .replace(/%7D/g, '}')
        .replace(/%5B/g, '[')
        .replace(/%5D/g, ']')
        .replace(/%7C/g, '|')
        .replace(/%23/g, '#')
        .replace(/%E2%99%82/g, 'b')
        .replace(/%E2%99%85/g, '♭')
        .replace(/%E2%99%AA/g, '♯');
    }

    // Split the input into lines
    const lines = input.split("\n");
    // Extract the title and artist
    const [title, artist] = lines[0].split(", by ");
    // Extract the chord progression
    const chords = lines.slice(1).join(' ').trim();

    // Encode the title and artist
    const encodedTitle = encodeSpecialChars(title.trim());
    const encodedArtist = encodeSpecialChars(artist.trim());

    // Encode the chords
    const encodedChords = encodeSpecialChars(chords);

    // Construct the final output
    const output = `irealb://${encodedTitle}=${encodedArtist}==Jazz=E-D==${encodedChords}==0=0`;

    return output;
  }

  // Example input
  const input = `Translation, by Fillip Smith
4/4𝄃Em G9+ | 𝄎  |G13b9   |Bbm7b5   ||𝄆Am   |F#°∆7/A   𝄇Em   |B7   𝄂`;

  // Translate the input to output
  const output = convertToIrealPro(input);
  // console.log(output);



  // NEW ADDITION ACRYLICODE
  // ///////////////////////
  // const saveSong = async (song: Song) => {
  //   const usersRef = collection(firestore, 'users')
  //   const userDoc = doc(usersRef, email)

  //   getDoc(userDoc).then(async snapshot => {
  //     const data = snapshot.data()
  //     const userSongs = data && data["songs"] ? data['songs'] : []

  //     const songsWithSameStarting = userSongs.filter((userSong: Song) => userSong.songTitle.startsWith(song.songTitle))
  //     const songNumber = songsWithSameStarting.length + 1
  //     const songTitle = `${song.songTitle} ${songNumber}`
  //     song.songTitle = songTitle
  //     const allUserSongs = [...userSongs, song]
  //     await setDoc(userDoc, { songs: allUserSongs }, { merge: true })
  //     setUserSongs(allUserSongs)
  //     setAllSongs([...defaultSongs, ...allUserSongs])
  //   })
  // }


  // const markChordForReplacement = (index: number) => {
  //   setSelectedChordIndex(index);
  // };

  // const replaceChordInChart = (selectedChord: string) => {
  //   if (selectedChordIndex !== null) {
  //     const updatedChart = chordChart.map((chord, idx) => {
  //       if (idx === selectedChordIndex) {
  //         return { name: selectedChord, isEditable: false };
  //       }
  //       return chord;
  //     });
  //     setChordChart(updatedChart);
  //     setSelectedChordIndex(null); // Reset the selection
  //   }
  // };

  // const handleChordChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  //   const updatedChart = [...chordChart];
  //   updatedChart[index].name = event.target.value;
  //   setChordChart(updatedChart);
  // };

  // const toggleEdit = (index: number) => {
  //   const updatedChart = chordChart.map((chord, idx) => {
  //     if (idx === index) {
  //       return { ...chord, isEditable: !chord.isEditable };
  //     }
  //     return chord;
  //   });
  //   setChordChart(updatedChart);
  // };

  // END Chord Chart handlers


  const [showWarning, setShowWarning] = useState(false); // finish this!
  const toggleWarning = () => {
    setShowWarning(prev => !prev);
  };

  // Finish this!
  // const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  // const toggleTooltip = () => {
  //   setIsTooltipVisible(prev => !prev);
  // };



  // const [lockedMode, setLockedMode] = useState<number | null>(null); // null when no mode is locked, 0 for major, 1 for minor



  // Palettes Start
  const [selectedPalette, setSelectedPalette] = useState(defaultPalettes[0].label);

  const [allPalettes, setAllPalettes] = useState<Palette[]>(defaultPalettes);
  const [userPalettes, setUserPalettes] = useState<Palette[]>([]);
  const [customPaletteName, setCustomPaletteName] = useState<string>('');

  const updatePalettes = async (email: string, label: string, majorLabels: string[], minorLabels: string[]) => {
    const usersRef = collection(firestore, 'users')
    const newUserDoc = doc(usersRef, email)
    const palette = allPalettes.find(palette => palette.label === label)

    const palettesToUpdate = [...userPalettes]
    if (!palette) {
      palettesToUpdate.push({ label, majorLabels, minorLabels })
    } else {
      const index = palettesToUpdate.indexOf(palette)
      palettesToUpdate[index] = { label, majorLabels, minorLabels }
    }

    // NEW ADDITION ACRYLICODE
    // await setDoc(newUserDoc, { labelSets: palettesToUpdate }, { merge: true })
    await setDoc(newUserDoc, { labelSets: palettesToUpdate })
    syncPalettes(email)
  }

  const deletePalette = async (email: string, label: string) => {
    const usersRef = collection(firestore, 'users')
    const newUserDoc = doc(usersRef, email)
    const palettesToUpdate = userPalettes.filter(palette => palette.label !== label)
    // NEW ADDITION ACRYLICODE
    // await setDoc(newUserDoc, { labelSets: palettesToUpdate }, { merge: true })
    await setDoc(newUserDoc, { labelSets: palettesToUpdate })
    syncPalettes(email)
  }
  // Palettes END


  const handleToggleLock = (note: number | null) => {
    setLockedNote(lockedNote === note ? null : note);
  };

  const handleModeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMode = parseInt(event.target.value, 10);
    if (lockedNote === null) {
      setMode(selectedMode);
    } else {
      setLockedMode(selectedMode);
    }
  };


  const getMidiNoteFromKey = (key: string): number | null => {
    const baseMap: { [key: string]: number } = {
      'a': 60,
      'w': 61,
      's': 62,
      'e': 63,
      'd': 64,
      'f': 65,
      't': 66,
      'g': 67,
      'y': 68,
      'h': 69,
      'u': 70,
      'j': 71,
      'k': 72,
      'o': 73,
      'l': 74,
      'p': 75,
      ';': 76,
      '\'': 77,
      ']': 78
    };
    return baseMap[key] ? baseMap[key] + octaveOffset : null;
  };

  const masterVolume = -11




  useEffect(() => {
    if (!samplerRef.current) {
      samplerRef.current = new Tone.Sampler({
        // const sampler = new Tone.Sampler({
        urls: {
          "C2": "C2.mp3",
          "C1": "C1.mp3",
          "C3": "C3.mp3",
          "C4": "C4.mp3",
          "C5": "C5.mp3",
          "C6": "C6.mp3",
          "C7": "C7.mp3",
          "C8": "C8.mp3",
          "D#1": "Ds1.mp3",
          "D#2": "Ds2.mp3",
          "D#3": "Ds3.mp3",
          "D#4": "Ds4.mp3",
          "D#5": "Ds5.mp3",
          "D#6": "Ds6.mp3",
          "D#7": "Ds7.mp3",
          "F#1": "Fs1.mp3",
          "F#2": "Fs2.mp3",
          "F#3": "Fs3.mp3",
          "F#4": "Fs4.mp3",
          "F#5": "Fs5.mp3",
          "F#6": "Fs6.mp3",
          "F#7": "Fs7.mp3",
          "A1": "A1.mp3",
          "A2": "A2.mp3",
          "A3": "A3.mp3",
          "A4": "A4.mp3",
          "A5": "A5.mp3",
          "A6": "A6.mp3",
          "A7": "A7.mp3",
        },
        release: 1,
        baseUrl: "/samples/",
      }).toDestination();

      samplerRef.current.volume.value = masterVolume; // sets the master volume
    }
  }, []);



  // function playNote(note: string, velocity: number = 60) {
  //   if (shouldPlaySoundRef && samplerRef.current && audioInitialized) {
  //     const volumeDb = velocityToDb(velocity);
  //     samplerRef.current.volume.value = volumeDb; // dB used for master Volume, 0.0 - 1.0 used for individual samples
  //     samplerRef.current.triggerAttackRelease(note, "1m"); // 8n (8th note), 1m (1 measure)
  //   }
  // }


  const handleSoundToggle = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (!soundOn) { // If the sound is currently off
      // If Tone.js hasn't been initialized yet, start it
      if (!audioInitialized) {
        await Tone.start();
        console.log("Tone.js started successfully");
        setAudioInitialized(true);
      }
      Tone.Transport.start();
      console.log("Tone.js started/resumed");

      // Trigger a silent note to unlock audio
      // if (samplerRef.current) {
      //   samplerRef.current.triggerAttackRelease('C4', '16n', undefined, 0); // 0 volume
      // }

    } else { // If the sound is currently on
      Tone.Transport.pause();
      console.log("Tone.js paused");
      samplerRef.current!.releaseAll();

      // const allNotes = [...activeNotes, ...heldNotes, ...toggledNotes];
      // allNotes.forEach(note => {
      //   // if (activeNotes.includes(note) || heldNotes.includes(note) || toggledNotes.includes(note)) {
      //   samplerRef.current!.triggerRelease(Tone.Midi(note).toNote());
      //   // }
      // });
    }

    // Toggle the sound state
    console.log("Current soundOn state:", soundOn);
    setSoundOn(prevState => prevState);
  };


  useEffect(() => {
    shouldPlaySoundRef.current = soundOn && audioInitialized;
    setShouldPlaySound(soundOn && audioInitialized);
    console.log("shouldPlaySoundRef state updated:", shouldPlaySoundRef.current);
  }, [soundOn, audioInitialized]);


  // function playChord(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
  function playChord() {
    const allNotes = [...new Set([...activeNotes, ...heldNotes, ...toggledNotes])];
    // If sound is off, turn it on
    if (!soundOn && allNotes.length > 0) {
      if (!audioInitialized) {
        Tone.start();
        setAudioInitialized(true);
      }
      setSoundOn(true);
    }
    if (samplerRef.current) {
      allNotes.forEach(note => {
        if (allNotes.includes(note)) {
          samplerRef.current!.triggerRelease(Tone.Midi(note).toNote());
        }
      });
      if (isArpeggiateOn) {
        let uniqueMidiNotes = Array.from(new Set(allNotes)); // remove any duplicates
        let sortedMidiNotes = uniqueMidiNotes.sort((a, b) => a - b); // Sort notes from low to high
        // let arpSpeed = 10000 / parseInt(tempo.value, 10) / (1 + sortedMidiNotes.length / 50);
        let arpSpeed = 10000 / 120 / (1 + sortedMidiNotes.length / 50); // larger chords arpeggiate faster

        sortedMidiNotes.forEach((note, index) => {
          setTimeout(() => {
            samplerRef.current!.triggerAttack(Tone.Midi(note).toNote(), undefined, 0.65);
            // samplerRef.current!.triggerRelease(Tone.Midi(note).toNote(), Tone.now() + 0.5);
          }, index * arpSpeed); // 100ms interval between notes, adjust as needed
        });
      } else {
        allNotes.forEach(note => {
          samplerRef.current!.triggerAttack(Tone.Midi(note).toNote(), undefined, 0.65);
        });
      }
    }
  }

  // onCLick
  const clearAllNotes = () => {
    const allNotes = [...new Set([...activeNotes, ...heldNotes, ...toggledNotes])];
    if (sustainRef.current) {
      sustainRef.current = false
    }
    if (allNotes.length > 0) {
      setActiveNotes([]);
      setHeldNotes([]);
      setToggledNotes([]);
      setIsScale(false);
      setChordSymbol('');
      if (shouldPlaySoundRef.current && samplerRef.current) {
        allNotes.forEach(note => {
          samplerRef.current!.triggerRelease(Tone.Midi(note).toNote());
        });
      }
    }
  };

  // const clearToggledNotes = () => {
  //   if (sustainRef.current) {
  //     sustainRef.current = false
  //   }
  //   if (toggledNotes.length > 0) {
  //     setToggledNotes([]);
  //     setIsScale(false);
  //     setChordSymbol('');
  //     if (shouldPlaySoundRef.current && samplerRef.current) {
  //       toggledNotes.forEach(note => {
  //         samplerRef.current!.triggerRelease(Tone.Midi(note).toNote());
  //       });
  //     }
  //   }
  // };

  const nudgeToggledNotes = (amount: number) => {
    const allNotes = [...new Set([...heldNotes, ...toggledNotes])];
    if (heldNotes.length > 0) {
      setHeldNotes(heldNotes.map(note => note + amount));
    }
    if (toggledNotes.length > 0) {
      setToggledNotes(toggledNotes.map(note => note + amount));
    }
    if (shouldPlaySoundRef.current && samplerRef.current) {
      allNotes.forEach(note => {
        // if (activeNotes.includes(note) || heldNotes.includes(note) || toggledNotes.includes(note)) {
        samplerRef.current!.triggerRelease(Tone.Midi(note).toNote());
        // }
      });
    }
  };

  const syncPalettes = (email: string) => {
    const usersRef = collection(firestore, 'users')
    const userDoc = doc(usersRef, email)
    getDoc(userDoc).then(snapshot => {
      const data = snapshot.data()
      if (!data) return
      const userPalettes = data['labelSets']
      setUserPalettes(userPalettes)
      setAllPalettes([...defaultPalettes, ...userPalettes])
    })
  }

  // NEW ADDITION ACRYLICODE
  // /////////////////
  // const syncSongs = (email: string) => {
  //   const usersRef = collection(firestore, 'users')
  //   const userDoc = doc(usersRef, email)
  //   getDoc(userDoc).then(snapshot => {
  //     const data = snapshot.data()
  //     if (!data) return
  //     const songs = data['songs']
  //     setUserSongs(songs)
  //     setAllSongs([...defaultSongs, ...userSongs])
  //   })
  // }


  const navigate = useNavigate()

  const clearAllEmos = () => {
    setEmoClassLabelsMajor(defaultEmoClassLabelsMajor);
    setEmoClassLabelsMinor(defaultEmoClassLabelsMinor);
  };

  const [email, setEmail] = useState('')
  useEffect(() => {
    auth.onAuthStateChanged(
      (user) => {
        if (user && user.email) {
          setEmail(user.email)
          syncPalettes(user.email)
          // NEW ADDITION ACRYLICODE
          // syncSongs(user.email)
        } else {
          setEmail('')
          clearAllEmos()
          navigate("/login") // Comment out for offline work
        }
      },
      (e) => {
        console.log('error', e)
      }
    )
  }, [])

  {/*  Pie Chart Below */ }
  const pcClass = ['p-root', 'p-fifth', 'maj-second', 'maj-sixth', 'maj-third', 'maj-seventh', 'tritone', 'min-second', 'min-sixth', 'min-third', 'min-seventh', 'p-fourth'];
  // const pcClass = ['p-root', 'min-second', 'maj-second', 'min-third', 'maj-third', 'p-fourth', 'tritone', 'p-fifth', 'min-sixth', 'maj-sixth', 'min-seventh', 'maj-seventh'];
  const emoClass = ['emo-p-root', 'emo-p-fifth', 'emo-maj-second', 'emo-maj-sixth', 'emo-maj-third', 'emo-maj-seventh', 'emo-tritone', 'emo-min-second', 'emo-min-sixth', 'emo-min-third', 'emo-min-seventh', 'emo-p-fourth'];

  // abbreviated labels
  const pcClassLabels = ['Root', 'Perf 5', 'Maj 2', 'Maj 6', 'Maj 3', 'Maj 7', 'Tritone', 'Min 2', 'Min 6', 'Min 3', 'Min 7', 'Perf 4'];



  // const defaultEmoClassLabelsMajor = ['Peace', 'Brightness', 'Yearning', 'Enchantment', 'Eeriness', 'Mysticism', 'Fortitude', 'Anticipation', 'Suspense', '\u00A0existence', '\u00A0prominence', 'Hope']
  // const defaultEmoClassLabelsMinor = ['Bewitchment', 'Rebellion', 'Despair', 'Dread', 'Gloom', 'Apprehension', 'Sadness', 'Pensiveness', 'Wistfulness', '\u00A0perserverance', '\u00A0solemnity', 'Longing']

  // create state for each descriptor
  const [emoClassLabelsMajor, setEmoClassLabelsMajor] = useState<string[]>(defaultPalettes[0].majorLabels);
  const [emoClassLabelsMinor, setEmoClassLabelsMinor] = useState<string[]>(defaultPalettes[0].minorLabels);

  // create a handler for changes
  const handleInputChangeMajor = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmoClassLabelsMajor = [...emoClassLabelsMajor];
    newEmoClassLabelsMajor[index] = event.target.value;
    setEmoClassLabelsMajor(newEmoClassLabelsMajor);
  };

  const handleInputChangeMinor = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmoClassLabelsMinor = [...emoClassLabelsMinor];
    newEmoClassLabelsMinor[index] = event.target.value;
    setEmoClassLabelsMinor(newEmoClassLabelsMinor);
  };

  // Choose the appropriate labels based on the mode
  const [emoClassLabels, setEmoClassLabels] = useState<string[]>(mode === 0 ? defaultEmoClassLabelsMajor : defaultEmoClassLabelsMinor);

  useEffect(() => {
    setEmoClassLabels(mode === 0 ? emoClassLabelsMajor : emoClassLabelsMinor);
  }, [emoClassLabelsMajor, emoClassLabelsMinor, mode]);

  // const reorder = [9, 10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8];  // adjust as needed
  const reorder = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];  // adjust as needed
  // const reorder = [9, 4, 11, 6, 1, 8, 3, 10, 5, 0, 7, 2];  // adjust as needed


  // const rootNote = Math.min(...midiNotes); // Math.min uses the lowest midi note
  // const [chordSymbols, setChordSymbols] = useState<string | null>('No Chord');
  const [chordSymbols, setChordSymbols] = useState<string>("loading...");
  const [chordSymbol, setChordSymbol] = useState<string>(" ");
  const [chordSymbolTwo, setChordSymbolTwo] = useState<string>(" ");
  const [rootNote, setRootNote] = useState<number>(0);  // default root note
  const [csOptions, setCsOptions] = useState<string[]>([]);
  const root_pc = rootNote % 12;



  // useEffects Below //

  // Active Notes Ref
  useEffect(() => {
    activeNotesRef.current = activeNotes;
    if (selectedChordIndexRef.current !== null) {
      setToggledNotes([]);
    }
  }, [activeNotes]);

  // Toggled Notes Ref
  useEffect(() => {
    toggledNotesRef.current = toggledNotes;
  }, [toggledNotes]);

  // Held Notes Ref
  useEffect(() => {
    heldNotesRef.current = heldNotes;
  }, [heldNotes]);

  // Clear Chord Symbol(s)
  useEffect(() => {
    if (!sustain && activeNotes.length === 0 && heldNotes.length === 0 && toggledNotes.length === 0) {
      setChordSymbols('');
      setChordSymbol('');
      setChordSymbolTwo('');
    }
  }, [sustain, activeNotes, heldNotes, toggledNotes]);

  // SustainRef
  useEffect(() => {
    sustainRef.current = sustain;
  }, [sustain]);


  // MIDI Events
  useEffect(() => {
    if (!navigator.requestMIDIAccess) {
      console.log("WebMIDI is not supported in this browser.");
      return;
    }


    navigator.requestMIDIAccess({ sysex: false }).then(onMIDISuccess, onMIDIFailure);

    function onMIDISuccess(midiAccess: WebMidi.MIDIAccess) {
      setMidiAccess(midiAccess);
      for (let input of midiAccess.inputs.values()) {
        input.onmidimessage = getMIDIMessage;
      }
    }

    function onMIDIFailure() {
      console.log('Could not access your MIDI devices.');
    }


    function getMIDIMessage(midiMessage: WebMidi.MIDIMessageEvent) {
      let data = midiMessage.data; // this gives us our [command/channel, note, velocity] data.

      // Log the MIDI data
      // console.log('MIDI data', data); // MIDI data [144, 63, 73]

      if (data[0] === 248 || data[0] === 254 || data[0] === 214) { // If it's a timing clock or active sensing message
        return; // Just ignore it and exit the function early
      }
      if (data[0] !== 248 && data[0] !== 254 && data[0] !== 214) {
        console.log('MIDI data', data);
      }

      if (!(data instanceof Array || data instanceof Uint8Array) || data.length < 2) {
        console.error('Unexpected MIDI data:', data);
        return;
      }


      if (typeof data[1] !== 'number' || data[1] < 0 || data[1] > 127) {
        console.error('Invalid MIDI note number:', data[1]);
        return;
      }

      let status = data[0] & 0xF0; // Mask out the channel bits

      // if ((data[0] === 144 || data[0] === 150 || data[0] === 148) && data[2] > 0) {  // Note on event, 144 for most midi controllers, 150 for old Alesis Vi controller
      if (status === 144 && data[2] > 0) { // Note On event for any channel
        let pc = chromaticToFifths(data[1], rootNoteRef.current);
        console.log("data[1], rootNote, pc", data[1], rootNoteRef.current, pc);
        setKeyflash(prevNotes => [...prevNotes, data[1]]);
        setLabelFlashes(prev => ({ ...prev, [pc]: true }));
        setPcLabelFlashes(prev => ({ ...prev, [pc]: true }));
        setTimeout(() => setKeyflash(prevNotes => prevNotes.filter(note => note !== data[1])), 65);
        setTimeout(() => {
          setLabelFlashes(prev => {
            let updated = { ...prev };
            updated[pc] = false;
            return updated;
          });
          setPcLabelFlashes(prev => { // Turn off the pcClassLabel flash after a delay
            let updated = { ...prev };
            updated[pc] = false;
            return updated;
          });
        }, 65);

        setActiveNotes(prevNotes => [...prevNotes, data[1]]);
        // setToggledNotes([]); // Clear toggledNotes
        if (selectedChordIndexRef.current !== null) {
          setToggledNotes([]);
        }

        if (shouldPlaySoundRef.current) {

          // const noteName = midiToName(data[1], true);
          const noteName = Tone.Midi(data[1]).toNote();
          // const volumeDb = velocityToDb(data[2]); // convert MIDI velocity to dB for tone.js
          const velocityNormd = Math.min(Math.max((data[2] + 1) / (127 + 20), 0), 1);

          console.log("Note on event captured:", noteName); // Debug log
          // console.log("Note volume in dB", volumeDb)
          console.log("samplerRef.current, shouldPlaySoundRef", samplerRef.current, shouldPlaySoundRef.current)
          // sampler.triggerAttack(noteName, undefined, velocity);

          // Check if the note is already active, held, or toggled (sustainRef tells us that)
          // and release the sounding sample to prep for retriggering
          if (activeNotes.includes(data[1]) && sustainRef.current && samplerRef.current && shouldPlaySoundRef.current) {
            samplerRef.current.triggerRelease(Tone.Midi(data[1]).toNote());
          }

          if (samplerRef.current && shouldPlaySoundRef.current) {
            // samplerRef.current.volume.value = volumeDb;
            // samplerRef.current.volume.value = -13;
            // samplerRef.current.triggerAttack(Tone.Midi(data[1]).toNote());
            samplerRef.current.triggerAttack(Tone.Midi(data[1]).toNote(), undefined, velocityNormd);
          }
        }

        // } else if (data[0] === 128 || data[0] === 134 || ((data[0] === 144 || data[0] === 148) && data[2] === 0)) { // Note off event
      } else if ((status === 128 || (status === 144 && data[2] === 0))) { // Note Off event for any channel
        if (sustainRef.current) {
          // If sustain is on, move note to heldNotes
          // setHeldNotes(prevNotes => [...prevNotes, data[1]]);
          setHeldNotes(prevNotes => {
            if (!prevNotes.includes(data[1])) {
              return [...prevNotes, data[1]];
            } else {
              return prevNotes;
            }
          });

        }
        // Remove note from activeNotes
        setActiveNotes(prevNotes => prevNotes.filter(note => note !== data[1]));

        if (shouldPlaySoundRef.current && !sustainRef.current) {
          // const noteName = midiToName(data[1], true);
          const noteName = Tone.Midi(data[1]).toNote();
          // sampler.triggerRelease(noteName);
          if (samplerRef.current && shouldPlaySoundRef.current) {
            samplerRef.current.triggerRelease(Tone.Midi(data[1]).toNote());
          }
        }

      } else if (data[0] === 176 && data[1] === 64) { // Sustain pedal CC message

        if (data[2] >= 64) { // Pedal down (typically 127 for full press)

          sustainRef.current = true;

        } else { // Pedal up (0)

          sustainRef.current = false;

          // When pedal is released, clear heldNotes
          setHeldNotes([]);
          // setToggledNotes([]); // Clear toggledNotes

          if (samplerRef.current && shouldPlaySoundRef) {
            // const noteNames = heldNotesRef.current.map(midiNote => Tone.Midi(midiNote).toNote());
            const noteNames = heldNotesRef.current
              .filter(midiNote => !activeNotesRef.current.includes(midiNote))
              .map(midiNote => Tone.Midi(midiNote).toNote());
            samplerRef.current.triggerRelease(noteNames);
          }
        }
      }
    }
  }, []); // Pass an empty dependency array so this effect only runs once


  useEffect(() => {
    rootNoteRef.current = rootNote; // Update the ref with the latest rootNote
  }, [rootNote]); // Run this effect whenever rootNote changes  

  useEffect(() => {
    const combinedMidi = [...activeNotes, ...heldNotes, ...toggledNotes];
    setActivePcs(midiToPcs(combinedMidi));
    if (activePcs.length == 0 && isScale == true) {
      setIsScale(false)
      // alert("set Scale to False")
    }
  }, [activeNotes, heldNotes, toggledNotes]); // Run this effect whenever activeNotes, heldNotes, toggledNotes changes  

  // const velocityNormd = Math.min(Math.max((data[2] + 1) / (127 + 20), 0), 1);

  useEffect(() => {
    const handleKeyEvent = (event: KeyboardEvent) => {

      if (!songTitle.isEditable && !composer.isEditable && !isInputtingTextRef.current) {

        // console.log("MASTER USEEFFECT HANDLEKEYEVENT: isInputtingTextRef.current", isInputtingTextRef.current)

        const midiNote = getMidiNoteFromKey(event.key);
        if (midiNote !== null) {

          if (event.repeat) return;  // Ignore repeated keydown events

          if (event.type === "keydown") {

            console.log("Key pressed:", event.key);

            let pc = chromaticToFifths(midiNote, rootNoteRef.current);
            console.log("midiNote, rootNote, pc", midiNote, rootNoteRef.current, pc);
            setKeyflash(prevNotes => [...prevNotes, midiNote]);
            setLabelFlashes(prev => ({ ...prev, [pc]: true }));
            setPcLabelFlashes(prev => ({ ...prev, [pc]: true }));
            setTimeout(() => setKeyflash(prevNotes => prevNotes.filter(note => note !== midiNote)), 65);
            setTimeout(() => {
              setLabelFlashes(prev => {
                let updated = { ...prev };
                updated[pc] = false;
                return updated;
              });
              setPcLabelFlashes(prev => {
                let updated = { ...prev };
                updated[pc] = false;
                return updated;
              });
            }, 65);

            setActiveNotes(prevNotes => [...prevNotes, midiNote]);
            // setToggledNotes([]);

            if (shouldPlaySoundRef.current) {

              const noteName = Tone.Midi(midiNote).toNote();
              // const noteName = midiToName(midiNote, true);

              console.log("Key down event captured:", noteName);
              console.log("samplerRef.current, shouldPlaySoundRef", samplerRef.current, shouldPlaySoundRef.current);

              if (activeNotes.includes(midiNote) && sustainRef.current && samplerRef.current) {
                samplerRef.current.triggerRelease(noteName);
              }

              if (samplerRef.current) {
                samplerRef.current.triggerAttack(noteName, undefined, 0.75);
              }
            }

          } else if (event.type === "keyup") {
            setActiveNotes(prevNotes => prevNotes.filter(note => note !== midiNote));

            if (shouldPlaySoundRef.current && samplerRef.current) {
              samplerRef.current.triggerRelease(Tone.Midi(midiNote).toNote());
            }
          }

        } else if (event.type === "keydown" && !isInputtingTextRef.current) {
          // console.log("isInputtingTextRef.current", isInputtingTextRef.current)
          if (event.key === 'z' || event.key === 'x') {
            if (event.key === 'z' && octaveOffset > -48) {
              setOctaveOffset(octaveOffset - 12);
            } else if (event.key === 'x' && octaveOffset < 36) {
              setOctaveOffset(octaveOffset + 12);
            }
            return;
          } else if (event.key === 'ArrowLeft') {
            chartNavigation('backward');
          } else if (event.key === 'ArrowRight') {
            chartNavigation('forward');
          } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            chartNavigation('jump-backward');
          } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            chartNavigation('jump-forward');
          } else if (event.key === 'Enter') {
            event.preventDefault();
            if (chordSymbol != '') {
              addEntryToChart(chordSymbol, false);
            }
          } else if (event.key === '/') {
            addEntryToChart(SEPARATORS.barline, true);
          } else if (event.key === ' ') {
            event.preventDefault();
            if (chordChart.length > 0) {
              setPlaySongOn(prevState => !prevState);
            } else {
              playChord();
            }
          } else if (event.key === 'Backspace') {
            if (selectedChordIndex !== null) {
              deleteChordFromChart(selectedChordIndex);
            }
          } else if (event.key === 'Escape') {
            if (selectedChordIndex != null) {
              setSelectedChordIndex(null);
              releaseToggledNotes();
              setToggledNotes([]);
            }
            // } else if (event.key === 'CapsLock') {
            //   // Check the state of CapsLock when pressed
            //   if (event.getModifierState('CapsLock')) {
            //     sustainRef.current = true; // Caps Lock is on
            //   } else {
            //     sustainRef.current = false; // Caps Lock is off
            //   }
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyEvent);
    window.addEventListener("keyup", handleKeyEvent);
    return () => {
      window.removeEventListener("keydown", handleKeyEvent);
      window.removeEventListener("keyup", handleKeyEvent);
    };
  }, [octaveOffset, selectedChordIndex, chordChart, chordSymbol, songTitle, composer, isInputtingTextRef]);


  // Convert chromatic pitch classes to circle of 5ths pitch classes
  const chromaticToFifths = function (note: number, rootNote: number = 0) {
    return ((note + 12 - rootNote) * 7) % 12;
  };

  // Convert MIDI notes to pitch classes
  const midiToPcs = (midiNotes: number[]): number[] => {
    const pitchClasses = midiNotes.map(note => note % 12);
    return [...new Set(pitchClasses)]; // Remove duplicates
  };


  // http requests
  useEffect(() => {

    const computedMidiNotes = [...new Set([...activeNotes, ...heldNotes, ...toggledNotes])]; // set just in case duplicates
    const rootNote = computedMidiNotes.length > 0 ? Math.min(...computedMidiNotes) : 0;

    // Update the state with the new midiNotes
    setMidiNotes(computedMidiNotes);

    if (computedMidiNotes.length >= 1) {
      // Convert state to the format expected by the Python script
      const data = { notes: computedMidiNotes };

      // Send a POST request to your Flask server
      // axios.post('http://localhost:5000/sentiserver', data)
      // axios.post('http://127.0.0.1:5000/sentiserver', data)

      axios.post(server, data)
        // axios.post('/sentiserver', data)
        .then(response => {
          // Handle the response data here
          console.log('Complete server response:', response);
          console.log('Received response from server:', response.data);
          // const chordSymbols = response.data.cs_display;
          // const rootNote = response.data.root_pc;
          const { bass_note_pc, is_scale, scale_code } = response.data;
          setIsScale(is_scale);
          setScaleCode(scale_code);

          console.log('scaleCode:', scaleCode);

          if (response.data.cs_options) {
            setCsOptions(response.data.cs_options);
            setChordSymbol(response.data.cs_options[0]);
            if (response.data.cs_options[1]) {
              setChordSymbolTwo(response.data.cs_options[1]);
            }
            setChordSymbols(response.data.cs_options.join('\n'));
          } else {
            console.warn('response.data.cs_options is undefined');
          }

          // Only update rootNote and keyCenter if there's no lockedNote
          if (lockedNote === null) {
            setRootNote(response.data.root_pc);
            setKeyCenter(response.data.pc_options[0]);
            setMode(response.data.minmaj);
          } else {
            setKeyCenter(lockedNote !== null ? lockedNote : response.data.pc_options[0]);
            setMode(lockedMode !== null ? lockedMode : response.data.minmaj);
          }

          setModes(response.data.modes);
          setPcSorted(response.data.pc_sorted);
          setPcWeightsSorted(response.data.pc_weights_sorted);
          setCsOptions(response.data.cs_options);

          if (isScale) {
            setPcWeightsSorted([]);
            setRootNote(bass_note_pc);
            if (scaleCode !== "") {
              setScaleUrl("https://ianring.com/musictheory/scales/" + scaleCode);
            } else {
              console.warn('Scale code is undefined');
              // Handle the scenario where scale_code is undefined
            }
          }

        })
        .catch(error => {
          // Handle any errors here
          console.error('Error sending request to server:', error);
        });
    }
  }, [activeNotes, heldNotes, toggledNotes, lockedNote, lockedMode, scaleCode]);


  // Emo Labels
  // Choose the appropriate Emo labels based on the mode
  useEffect(() => {
    // Decide which mode to use based on whether a note is locked
    const currentMode = lockedNote === null ? mode : lockedMode;
    // Choose the appropriate labels based on the mode
    const emoClassLabels = currentMode === 0 ? emoClassLabelsMajor : emoClassLabelsMinor;
    // Update the state with the new labels
    setEmoClassLabels(emoClassLabels);
  }, [mode, lockedMode, lockedNote, emoClassLabelsMajor, emoClassLabelsMinor]);


  // Active Emo Labels
  useEffect(() => {
    let newActiveEmoLabels: string[] = [];
    pcClass.forEach((pc, i) => {
      // console.log("rootNote:", rootNote)
      const isActive = activeNotes.map(note => chromaticToFifths(note, rootNote)).includes(i) ||
        heldNotes.map(note => chromaticToFifths(note, rootNote)).includes(i) ||
        toggledNotes.map(note => chromaticToFifths(note, rootNote)).includes(i);
      if (isActive) {
        newActiveEmoLabels.push(emoClassLabels[i]);
      }
    });
    setActiveEmoLabels(newActiveEmoLabels);

    if (pcSorted.length > 1 && pcWeightsSorted[1] >= gravityThreshold) {
      const nextRootNote = pcSorted[1];
      const nextRootMode = modes[1];
      let emoClassLabelsNextRootTemp = nextRootMode === 0 ? emoClassLabelsMajor : emoClassLabelsMinor;
      setEmoClassLabelsNextRoot(emoClassLabelsNextRootTemp); // Update state variable here
      let newActiveEmoLabelsNextRoot: string[] = [];

      pcClass.forEach((pc, i) => {
        const isActive = activeNotes.map(note => chromaticToFifths(note, nextRootNote)).includes(i) ||
          heldNotes.map(note => chromaticToFifths(note, nextRootNote)).includes(i) ||
          toggledNotes.map(note => chromaticToFifths(note, nextRootNote)).includes(i);
        if (isActive) {
          const label = emoClassLabelsNextRootTemp[i];
          // if (!newActiveEmoLabels.includes(label)) { // removed this check to include all emo labels of each chord
          newActiveEmoLabelsNextRoot.push(label);
          //}
        }
      });
      setActiveEmoLabelsNextRoot(newActiveEmoLabelsNextRoot);
    } else {
      setActiveEmoLabelsNextRoot([]); // Clear the labels if the condition is not met
    }
  }, [modes, activeNotes, heldNotes, toggledNotes, pcSorted, rootNote, emoClassLabels, emoClassLabelsMajor, emoClassLabelsMinor, pcWeightsSorted]);


  const hasChanges = () => {
    const currentPalette = allPalettes.find(({ label }) => label === selectedPalette);
    if (!currentPalette) return false
    const isPaletteEqual = currentPalette.majorLabels === emoClassLabelsMajor && currentPalette.minorLabels === emoClassLabelsMinor
    return isPaletteEqual
  }

  // Label Flashes
  useEffect(() => {
    let newLabelFlashes: Record<number, boolean> = {};

    // Check if the condition is met
    const thresholdMet = (pcSorted.length > 1 && pcWeightsSorted[1] >= gravityThreshold);


    for (let note of keyflash) {
      let pc = chromaticToFifths(note, (pcSorted[1] || 0)); // pcSorted[1] || 0 ensures a fallback of 0 if pcSorted[1] doesn't exist
      // Only apply the label flash if the condition is met
      if (thresholdMet) {
        newLabelFlashes[pc] = true;
        console.log("pc", pc);
      }
    }

    setLabelFlashes(prev => ({ ...prev, ...newLabelFlashes }));

    const timerId = setTimeout(() => {
      setLabelFlashes(prev => {
        let updated = { ...prev };
        for (let pc of Object.keys(newLabelFlashes)) {
          updated[parseInt(pc)] = false;
        }
        return updated;
      });
    }, 65);

    return () => clearTimeout(timerId);
  }, [keyflash, rootNote, pcSorted[1]]);


  // Display Key for ColorKey display with enharmonic spelling from mode, top left 
  useEffect(() => {
    // Determine the current mode based on lockedNote and lockedMode
    const currentMode = lockedNote !== null ? (lockedMode !== null ? lockedMode : 0) : mode;
    // Call midiToName with the necessary arguments
    const newName = midiToName(rootNote, false, rootNote, currentMode);
    // Update the state with the new name
    setKeyNameDisplay(newName);
  }, [rootNote, keyCenter, mode, lockedMode, lockedNote]);


  useEffect(() => {
    // Determine the palette index based on lockedNote's state
    const paletteIndex = lockedNote !== null ? 1 : 0;
    const newPalette = defaultPalettes[paletteIndex];

    setSelectedPalette(newPalette.label);
    setEmoClassLabelsMajor(newPalette.majorLabels);
    setEmoClassLabelsMinor(newPalette.minorLabels);
  }, [lockedNote]);




  // MIDI note numbers for 7 octaves from A0 to C8
  const notes = Array.from({ length: 88 }, (_, i) => i + 21); // MIDI note 21 is A0, i is pitch class (0 = C)

  // Filter the note numbers into white and black notes
  const whiteNotes = notes.filter(note => ![1, 3, 6, 8, 10].includes(note % 12)); // starting at A0, Bb at position 1 is first
  const blackNotes = notes.filter(note => [1, 3, 6, 8, 10].includes(note % 12));


  // Positions of the black keys between the white keys
  const blackKeyPositions = [1, 3, 4, 6, 7];
  const oneOctblackKeyPos = [0, 1, 3, 4, 5];


  // Toggled Notes
  const toggleNote = (note: number) => {
    if (toggledNotes.includes(note)) {
      setToggledNotes(toggledNotes.filter(n => n !== note));
    } else {
      setToggledNotes([...toggledNotes, note]);
    }
  };

  const notesInOctave = Array.from({ length: 12 }, (_, i) => i + rootNote);
  // console.log('notesInOctave:', notesInOctave)
  const inactiveOpacity = 0.5;
  const activeOpacity = 1; // unused. Use for basic piano key display activity, no scale degrees or Emos


  // console.log('keyCenter', keyCenter, 'mode:', mode);

  // console.log("Rendering with activeEmoLabelsNextRoot:", activeEmoLabelsNextRoot);
  if (!email) {
    return null // Comment out for offline work
  }

  // const scaleFontMultiplier = isScale ? 1 : 0;
  // console.log('isScale:', isScale, 'fontSizeMultiplier:', scaleFontMultiplier); // Debugging


  return (
    <>

      <div className="App">
        {/* Other components can be placed here as needed */}
        <HelpPopup /> {/* This will render the "Open Help" button and the modal functionality */}
        {/* Further components or content can follow */}
      </div>


      {/* Chord Display Below */}
      <div id="chord-container" className="chord-display"
        style={{
          backgroundColor: '#1a1a1a', width: '20.5dvw', height: '31.5dvw', padding: '0.5dvw',
          marginLeft: '2.5dvw', marginRight: '0dvw', marginTop: '1dvw', marginBottom: '1.0dvw',
          borderStyle: 'solid', borderColor: '#fff', borderSpacing: '2dvw', borderRadius: '1dvw', borderWidth: '0.05dvw', boxShadow: '0 0 0.5dvw 0.1dvw #aaa'
        }}>
        <p className="title-text">Chord Symbol</p><hr style={{
          backgroundColor: '#fff', fontSize: '0.3dvw', width: '100%', border: '0.03dvw solid #fff', boxShadow: '0 0 0.3dvw 0.2dvw #444'
        }}></hr>
        <p className="title-text"></p>
        <table
          //           title="* All possible chord symbols arranged by the most likely perceived root.&#13;&#13;
          // * Chord roots are prioritized by the way the chord feels which may not be the most practical way to read it on a chart. (Notation mode coming soon!)&#13;&#13;
          // * Scale names appear when all of its notes are played within one octave. Click the scale name for details."
          style={{
            fontSize: isScale
              ? `${2.7 - (chordSymbol.length * 0.04)}dvw`
              : `${3.7 - (chordSymbols.length * 0.001) - (chordSymbol.length * 0.1)}dvw`,
            paddingTop: isScale ? '1dvw' : '0dvw',
            fontStyle: isScale ? 'italic' : 'normal',
            border: "none",
            borderSpacing: "0.2dvw",
            margin: "0dvw",
            backgroundColor: "transparent"
          }}>
          {chordSymbols.split('\n').map((symbol, index) => (
            <tr key={index} className={index === 0 ? "chord-symbol" : (pcWeightsSorted[index] >= gravityThreshold ? "chord-symbol-poly" : "chord-symbols")}
              style={index === 0 ? {} : {
                color: `rgb(
                ${pcWeightsSorted[index] * 3.2 + 70}, 
                ${pcWeightsSorted[index] * 3.2 + 70}, 
                ${pcWeightsSorted[index] * 3.2 + 70}
            )`,
                fontSize: `${2.6 - (chordSymbols.length * 0.003) - (symbol.length * 0.03)}dvw`
              }}
            >
              <td className="chord-percent" style={{ border: "none", margin: "0", backgroundColor: "transparent" }}>
                {midiNotes.length >= 2 && pcWeightsSorted[index] !== undefined ? `${pcWeightsSorted[index].toFixed(1)}%\u00A0` : ""}
              </td>
              <td style={{ border: "none", margin: "0", backgroundColor: "transparent" }}>
                {isScale ? <a style={{ color: "rgb(256, 256, 256)", textDecoration: "none", fontFamily: 'Bravura Text' }}
                  href={`${scaleUrl}`} target="_blank" rel="noopener noreferrer">{symbol} {/*} + " " + activePcs.sort((a, b) => a - b)} */}
                </a>
                  : <span className="chord" onClick={() => addEntryToChart(symbol, false)}>{symbol}</span>
                }
              </td>
            </tr>
          ))}
        </table>


        {/* Chord Chart Message */}
        {chordChart.length > 0 ? (
          <div style={{
            padding: '1dvw', bottom: '0dvw', position: 'absolute', textAlign: 'center', fontFamily: 'Trebuchet MS', fontSize: '0.9dvw', fontWeight: '700', color: '#777'
          }}>
            {"SAVE and EXPORT chart coming soon!"}<br></br>
            {'For now use "Copy Chord Chart" '}<span style={{ verticalAlign: '-0.3dvw', fontSize: '1.4dvw' }}>{'\u29C9'}</span>
          </div>
        ) : (
          activePcs.length >= 0 ? (
            <div style={{
              padding: '1dvw', bottom: '0dvw', position: 'absolute', textAlign: 'center', fontFamily: 'Trebuchet MS', fontSize: '0.9dvw', fontWeight: '100', color: '#777'
            }}>
              {isScale ? (
                "Click scale name above for scale info"
              ) : (
                <>
                  {"NEW FEATURE"}<br></br>
                  {"Click any chord symbol above to build charts"}
                </>
              )}
            </div>
          ) : (<span></span>)
        )}
      </div >
      {/* Chord Chart Message End */}

      {/*
        \uD834\uDD34 = common time
        \uE08E = slash
        \u00A0 = space
        \uE084 = time signature 4
        \uD834\uDD03 = Double bar start
        \uD834\uDD02 = Double bar end
      */}


      {/* Emo Labels Display */}
      <div className="emo-display"
        style={{
          fontSize: `${1.3 - (chordSymbols.length * 0.0001)}dvw`,
          backgroundColor: '#1a1a1a', width: '18.5dvw', height: '31.5dvw', padding: '0.5dvw',
          marginLeft: '2dvw', marginRight: '1dvw', marginTop: '1dvw', marginBottom: '1.0dvw',
          borderStyle: 'solid', borderColor: '#fff', borderSpacing: '2dvw', borderRadius: '1dvw', borderWidth: '0.05dvw', boxShadow: '0 0 0.5dvw 0.1dvw #aaa'
        }}>
        <span className="title-text">Sentiment {'\u2001'}</span>
        <span style={{
          fontSize: '0.9dvw', fontFamily: 'Trebuchet MS', color: 'grey'
        }}> [<a href="#emo-inputs" style={{ color: 'grey', textDecoration: 'none' }}>Customize</a>]</span>
        <hr style={{
          fontSize: '0.3dvw', width: '100%', border: '0.03dvw solid #fff', boxShadow: '0 0 0.3dvw 0.2dvw #444'
        }}></hr>

        <div style={{ height: '0.9dvw' }}> </div>
        {/* Add Chord Symbol to top of Sentiments */}
        {activeEmoLabels.some(label => label) && (
          <text fill="grey" className='musicfont'
            style={{
              fontSize: `${1.4 - (chordSymbols.length * 0.001)}dvw`,
              // fontFamily: 'Trebuchet MS',
              // fontStyle: "italic",
              // fontWeight: "700",
              // stroke: "rgb(160,160,160)",
              // strokeWidth: "0.1",
              // border: "none", 
              borderSpacing: "0.0dvw",
              color: "grey",
              opacity: isScale ? 1 : `${pcWeightsSorted[0] * .03 + 0}`,
              margin: "0dvw"
            }}>
            {chordSymbol}
          </text>
        )}
        {/* Add Chord Symbol to top of Sentiments End */}
        {activeEmoLabels.map((label, index) => {
          const pcIndex = emoClassLabels.indexOf(label);
          const emoClassName = emoClass[pcIndex];
          return (
            <div key={index} className={`${emoClassName} ${labelFlashes[pcIndex] ? 'flash-emo' : ''}`} style={{
              fontSize: `${1.4 - (chordSymbol.length * 0.0001)}dvw`,
              fontWeight: "600",
              fontStyle: "bold",
              opacity: `${pcWeightsSorted[0] * .03 + 0}`,
              textIndent: "0.8dvw",
              // background: 'rgb(30,30,30)',
              margin: "0dvw"
              //   color: `rgb(
              //     ${pcWeightsSorted[0] * 3.2 + 100}, 
              //     ${pcWeightsSorted[0] * 3.2 + 100}, 
              //     ${pcWeightsSorted[0] * 3.2 + 100}
              // )`
            }}>{label}</div>
          )
        })}
        <p></p>
        <div>
          {
            (pcSorted.length > 1 && pcWeightsSorted[1] >= gravityThreshold)
              ? <>
                {/* Add Chord Symbol to secondary Sentiments */}
                <text fill="grey" className='musicfont'
                  style={{
                    fontSize: `${1.4 - (chordSymbolTwo.length * 0.001)}dvw`,
                    // fontFamily: 'Trebuchet MS',
                    // fontStyle: "italic",
                    // fontWeight: "700",
                    color: "grey",
                    opacity: `${pcWeightsSorted[1] * .03 + 0}`,
                    margin: "1.7dvw",
                  }}>
                  {chordSymbolTwo}
                </text>
                {/* Add Chord Symbol to secondary Sentiments End */}
                {
                  activeEmoLabelsNextRoot.map((label, index) => {
                    const pcIndex = emoClassLabelsNextRoot.indexOf(label);
                    const emoClassNameNextRoot = emoClass[pcIndex];
                    return (
                      <div key={`nextRoot-${index}`} className={`${emoClassNameNextRoot} ${labelFlashes[pcIndex] ? 'flash-emo' : ''}`} style={{
                        fontSize: `${1.3 - (chordSymbols.length * 0.0009)}dvw`,
                        fontWeight: "100",
                        textIndent: "2.6dvw",
                        // background: 'rgb(30,30,30)',
                        opacity: labelFlashes[pcIndex] === true ? "1" : `${pcWeightsSorted[1] * .02 + 0}`
                        // color: `rgb(
                        //   ${pcWeightsSorted[1] * 3.2 + 50}, 
                        //   ${pcWeightsSorted[1] * 3.2 + 50}, 
                        //   ${pcWeightsSorted[1] * 3.2 + 50}
                        // )`
                      }}>
                        {label}
                      </div>
                    );
                  })
                }
              </>
              : null
          }
        </div>
      </div>
      {/* Emo Labels Display */}


      {/*  Pie Chart Below */}
      {/* <div className="pie-container"> */}

      {chordChart.length == 0 ? (
        <div className='pie-chart'>
          <svg viewBox="0 5 64 64">
            <defs>
              <linearGradient id="p-root" gradientUnits="userSpaceOnUse">
                <stop offset="0%" stop-color="rgb(255, 255, 255)" stop-opacity={inactiveOpacity} />
                <stop offset="100%" stop-color="rgb(255, 255, 225)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="p-rootActive" gradientUnits="userSpaceOnUse">
                <stop offset="0%" stop-color="rgb(255, 255, 255)" stop-opacity="1" />
                <stop offset="100%" stop-color="rgb(255, 255, 225)" stop-opacity="1" />
              </linearGradient>

              <linearGradient id="min-second" gradientTransform="rotate(97, 0.5, 0.6)">
                {/* <stop offset="0%" stop-color="rgb(0, 235, 235)" stop-opacity={inactiveOpacity} /> */}
                <stop offset="0%" stop-color="rgb(225, 0, 0)" stop-opacity={inactiveOpacity} />
                <stop offset="20%" stop-color="rgb(225, 0, 0)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="min-secondActive" gradientTransform="rotate(97, 0.43, 0.50)">
                {/* <stop offset="0%" stop-color="rgb(0, 235, 235)" stop-opacity="1" /> */}
                <stop offset="0%" stop-color="rgb(225, 0, 0)" stop-opacity="1" />
                <stop offset="25%" stop-color="rgb(225, 0, 0)" stop-opacity="1" />
              </linearGradient>

              <linearGradient id="maj-second" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(255, 160, 0)" stop-opacity={inactiveOpacity} />
                <stop offset="100%" stop-color="rgb(255, 160, 0)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="maj-secondActive" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(255, 160, 0)" stop-opacity="1" />
                <stop offset="100%" stop-color="rgb(255, 160, 0)" stop-opacity="1" />
              </linearGradient>

              <linearGradient id="min-third" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(62, 62, 255)" stop-opacity={inactiveOpacity} />
                <stop offset="100%" stop-color="rgb(62, 62, 255)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="min-thirdActive" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(62, 62, 255)" stop-opacity="1" />
                <stop offset="100%" stop-color="rgb(62, 62, 255)" stop-opacity="1" />
              </linearGradient>

              <linearGradient id="maj-third" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(255, 255, 0)" stop-opacity={inactiveOpacity} />
                <stop offset="100%" stop-color="rgb(255, 225, 0)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="maj-thirdActive" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(255, 255, 0)" stop-opacity="1" />
                <stop offset="100%" stop-color="rgb(255, 225, 0)" stop-opacity="1" />
              </linearGradient>

              <linearGradient id="p-fourth" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(85, 210, 0)" stop-opacity={inactiveOpacity} />
                <stop offset="100%" stop-color="rgb(85, 210, 0)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="p-fourthActive" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(85, 210, 0)" stop-opacity="1" />
                <stop offset="100%" stop-color="rgb(85, 210, 0)" stop-opacity="1" />
              </linearGradient>

              <linearGradient id="tritone" gradientTransform="rotate(83, 0.5, 0.5)">
                {/* <stop offset="15%" stop-color="rgb(225, 0, 0)" stop-opacity={inactiveOpacity} /> */}
                <stop offset="15%" stop-color="rgb(0, 235, 235)" stop-opacity={inactiveOpacity} />
                <stop offset="45%" stop-color="rgb(0, 235, 235)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="tritoneActive" gradientTransform="rotate(83, 0.5, 0.5)">
                {/* <stop offset="15%" stop-color="rgb(225, 0, 0)" stop-opacity="1" /> */}
                <stop offset="15%" stop-color="rgb(0, 235, 235)" stop-opacity="1" />
                <stop offset="45%" stop-color="rgb(0, 235, 235)" stop-opacity="1" />
              </linearGradient>

              <linearGradient id="p-fifth" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(150, 140, 130)" stop-opacity={inactiveOpacity} />
                <stop offset="100%" stop-color="rgb(150, 140, 130)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="p-fifthActive" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(150, 140, 130)" stop-opacity="1" />
                <stop offset="100%" stop-color="rgb(150, 140, 130)" stop-opacity="1" />
              </linearGradient>

              <linearGradient id="min-sixth" gradientTransform="rotate(135, 0.9, 0.92)">
                {/* <stop offset="50%" stop-color="rgb(0, 235, 235)" stop-opacity={inactiveOpacity} /> */}
                <stop offset="50%" stop-color="rgb(170, 30, 170)" stop-opacity={inactiveOpacity} />
                <stop offset="75%" stop-color="rgb(170, 30, 170)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="min-sixthActive" gradientTransform="rotate(135, 0.9, 0.92)">
                {/* <stop offset="50%" stop-color="rgb(0, 235, 235)" stop-opacity={1} /> */}
                <stop offset="50%" stop-color="rgb(170, 30, 170)" stop-opacity={1} />
                <stop offset="75%" stop-color="rgb(170, 30, 170)" stop-opacity={1} />
              </linearGradient>

              <linearGradient id="maj-sixth" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(255, 115, 0)" stop-opacity={inactiveOpacity} />
                <stop offset="100%" stop-color="rgb(255, 115, 0)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="maj-sixthActive" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(255, 115, 0)" stop-opacity="1" />
                <stop offset="100%" stop-color="rgb(255, 115, 0)" stop-opacity="1" />
              </linearGradient>

              <linearGradient id="min-seventh" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(0, 1150, 125)" stop-opacity={inactiveOpacity} />
                <stop offset="100%" stop-color="rgb(0, 150, 125)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="min-seventhActive" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(0, 150, 125)" stop-opacity="1" />
                <stop offset="100%" stop-color="rgb(0, 150, 125)" stop-opacity="1" />
              </linearGradient>

              <linearGradient id="maj-seventh" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(255, 105, 175)" stop-opacity={inactiveOpacity} />
                <stop offset="100%" stop-color="rgb(255, 105, 175)" stop-opacity={inactiveOpacity} />
              </linearGradient>
              <linearGradient id="maj-seventhActive" gradientTransform="rotate(90)">
                <stop offset="0%" stop-color="rgb(255, 105, 175)" stop-opacity="1" />
                <stop offset="100%" stop-color="rgb(255, 105, 175)" stop-opacity="1" />
              </linearGradient>

              {/* <linearGradient id="majorGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" style={{ stopColor: "rgb(80,80,100)", stopOpacity: 1 }} />
                <stop offset="60%" style={{ stopColor: "rgb(220,200,160)", stopOpacity: 1 }} />
              </linearGradient> */}

              <linearGradient id="defaultGradient" x1="130%" y1="0%" x2="-35%" y2="0%">
                <stop offset="0%" style={{ stopColor: "rgb(255, 255, 255)", stopOpacity: 1 }} />
                <stop offset="0%" style={{ stopColor: "rgb(255, 254, 214)", stopOpacity: 1 }} />
                <stop offset="45.5%" style={{ stopColor: "rgb(243, 197, 157)", stopOpacity: 1 }} />
                <stop offset="51.5%" style={{ stopColor: "rgb(107, 117, 142)", stopOpacity: 1 }} />
                <stop offset="99%" style={{ stopColor: "rgb(39, 49, 91)", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: "rgb(0, 0, 0)", stopOpacity: 1 }} />
              </linearGradient>

              {/* <linearGradient id="majorGradient" x1="56%" y1="0%" x2="-99%" y2="0%">
                <stop offset="0%" style={{ stopColor: "rgb(255, 255, 255)", stopOpacity: 1 }} />
                <stop offset="1%" style={{ stopColor: "rgb(255, 254, 214)", stopOpacity: 1 }} />
                <stop offset="8.5%" style={{ stopColor: "rgb(243, 197, 157)", stopOpacity: 1 }} />
                <stop offset="91.5%" style={{ stopColor: "rgb(107, 117, 142)", stopOpacity: 1 }} />
                <stop offset="99%" style={{ stopColor: "rgb(39, 49, 91)", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: "rgb(0, 0, 0)", stopOpacity: 1 }} />
              </linearGradient> */}

              {/* <linearGradient id="majorGradient" x1="60%" y1="-10%" x2="-140%" y2="190%">
                <stop offset="0%" style={{ stopColor: "rgb(255, 255, 255)", stopOpacity: 1 }} />
                <stop offset="15%" style={{ stopColor: "rgb(255, 254, 214)", stopOpacity: 1 }} />
                <stop offset="25.5%" style={{ stopColor: "rgb(243, 197, 157)", stopOpacity: 1 }} />
                <stop offset="37.5%" style={{ stopColor: "rgb(107, 117, 142)", stopOpacity: 1 }} />
                <stop offset="74%" style={{ stopColor: "rgb(39, 49, 91)", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: "rgb(0, 0, 0)", stopOpacity: 1 }} />
              </linearGradient> */}

              <linearGradient id="majorGradient" x1="49%" y1="0%" x2="-70%" y2="0%">
                <stop offset="0%" style={{ stopColor: "rgb(255, 255, 255)", stopOpacity: 1 }} />
                <stop offset="1%" style={{ stopColor: "rgb(255, 254, 214)", stopOpacity: 1 }} />
                <stop offset="30.5%" style={{ stopColor: "rgb(243, 197, 157)", stopOpacity: 1 }} />
                <stop offset="61.5%" style={{ stopColor: "rgb(97, 107, 132)", stopOpacity: 1 }} />
                <stop offset="79%" style={{ stopColor: "rgb(39, 49, 91)", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: "rgb(0, 0, 0)", stopOpacity: 1 }} />
              </linearGradient>

              {/* <linearGradient id="minorGradient" x1="60%" y1="-80%" x2="-10%" y2="20%">
                <stop offset="0%" style={{ stopColor: "rgb(255, 255, 255)", stopOpacity: 1 }} />
                <stop offset="15%" style={{ stopColor: "rgb(255, 254, 214)", stopOpacity: 1 }} />
                <stop offset="30.5%" style={{ stopColor: "rgb(243, 197, 157)", stopOpacity: 1 }} />
                <stop offset="61.5%" style={{ stopColor: "rgb(107, 117, 142)", stopOpacity: 1 }} />
                <stop offset="89%" style={{ stopColor: "rgb(39, 49, 91)", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: "rgb(0, 0, 0)", stopOpacity: 1 }} />
              </linearGradient> */}

              <linearGradient id="minorGradient" x1="145%" y1="0%" x2="0%" y2="0%">
                <stop offset="0%" style={{ stopColor: "rgb(255, 255, 255)", stopOpacity: 1 }} />
                <stop offset="49%" style={{ stopColor: "rgb(107, 117, 162)", stopOpacity: 1 }} />
                <stop offset="63.5%" style={{ stopColor: "rgb(56, 67, 132)", stopOpacity: 1 }} />
                <stop offset="67%" style={{ stopColor: "rgb(34, 49, 121)", stopOpacity: 1 }} />
                <stop offset="70%" style={{ stopColor: "rgb(29, 39, 101)", stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: "rgb(0, 5, 56)", stopOpacity: 1 }} />
              </linearGradient>


            </defs>

            {pcClass.map((pc, i) => {
              // const isActive = activeNotes.includes(i % 12) || heldNotes.includes(i % 12) || toggledNotes.includes(i % 12);
              const isActive = activeNotes.map(note => chromaticToFifths(note, rootNote)).includes(i) ||
                heldNotes.map(note => chromaticToFifths(note, rootNote)).includes(i) ||
                toggledNotes.map(note => chromaticToFifths(note, rootNote)).includes(i);



              const radius = 23 - 6.5;  // pie chart radius adjust for text labels Root, Maj 2, etc
              const radius_two = 27 // pie chart radius adjust for text labels two, Emotions
              const midAngle = ((i) / pcClass.length) * 2 * Math.PI + rotation;  // mid angle for each slice

              const labelX = radius * Math.cos(midAngle);  // labelX depends on midAngle
              const labelY = radius * Math.sin(midAngle);  // labelY depends on midAngle
              const labelX_two = 0.99 * radius_two * Math.cos(midAngle);  // labelX depends on midAngle
              const labelY_two = 0.9 * radius_two * Math.sin(midAngle);  // labelY depends on midAngle


              return (
                <g key={i} transform="translate(32, 32)">
                  <path
                    key={i}
                    d={getPieSlicePath(i, pcClass.length)}
                    fill={`url(#${pc}${isActive ? 'Active' : ''})`}
                    className={`${isActive ? 'active-slice' : ''}`}
                    stroke="black"
                    stroke-width="0.15"
                    stroke-opacity="0.8"
                  />
                  <text x={labelX} y={labelY}
                    // fill="#000"
                    className={pcLabelFlashes[i] ? 'flash-pc-label' : ''}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={isActive ? "2.2" : "2.1"}
                    fontFamily="Trebuchet MS"
                    fontWeight={isActive ? '700' : '100'}
                    fill={isActive ? "black" : "rgb(40,40,40)"}
                  >
                    {pcClassLabels[i]}
                  </text>
                  <rect x={labelX_two - emoClassLabels[i].length * 1.0 / 2} y={labelY_two - 2 / 2} width={emoClassLabels[i].length * 1.0} height={2}
                    color={isActive ? 'grey' : 'white'}
                    // fill={isActive ? 'white' : 'grey'}
                    fill='rgb(0,0,0)'
                    fillOpacity={isActive ? '0.4' : '0'}
                    stroke="rgb(90,90,90)"
                    strokeOpacity={isActive ? '0.7' : '0'}
                    stroke-width="0.1"
                  />
                  <text x={labelX_two} y={labelY_two}
                    fill={isActive ? 'white' : 'grey'}
                    // fill="black"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="1.7"
                    fontFamily='Trebuchet MS'
                    fontStyle='italic'
                    // textDecoration={isActive ? '' : '0 0 10px #fff, 0 0 10px #fff, 0 0 22px #bbb, 0 0 42px #bbb,0 0 62px #bbb'}
                    // fontStyle={isActive ? 'normal' : 'italic'}
                    // stroke={isActive ? 'black' : 'grey'}
                    stroke={isActive ? 'rgb(255,255,255)' : 'rgb(0,0,0)'}
                    strokeOpacity="0.2"
                    stroke-width="0.0035dvw"
                    // strokeWidth="0.01dvh"
                    fontWeight={isActive ? '700' : '100'}
                  >
                    {emoClassLabels[i]}
                  </text>

                  {/* <rect x={-12} y={-4} width={24} height={8} */}
                  <circle cx={0} cy={0} r={11}
                    color="white"
                    // fill={isActive ? 'white' : 'grey'}
                    fill={
                      mode === 0 && chordSymbol != ''
                        ? 'url(#majorGradient)' // Case when mode is 0 major 'rgb(220,220,160)'
                        : (mode === 1 && chordSymbol != ''
                          ? 'url(#minorGradient)'  // Case when mode is 1 minor 'rgb(130, 130, 190)'
                          : (chordSymbol === ''
                            ? 'url(#defaultGradient)'  // Case when chordSymbol is an empty string
                            : 'rgb(100,100,100)')) // Default case if none of the above conditions are met
                    }
                    fillOpacity="1"
                    stroke="rgb(170,170,170)"
                    strokeOpacity="1"
                    stroke-width="0.16"
                  />

                  {/* Add Chord Symbol to center of Pie Chart Below */}
                  <text className="pie-chord-symbol" x={0} y={1} fill={mode === 0 && chordSymbol != ''
                    ? 'black' // Case when mode is 0 major 'rgb(220,220,160)'
                    : 'white'}
                    style={{
                      fontSize: isScale ? `${0.5 - (chordSymbol.length * 0.012)}dvh` : `${0.64 - (chordSymbol.length * 0.024)}dvh`,
                      // fontFamily: 'Trebuchet MS',
                      // stroke: "rgb(160,160,160)",
                      // strokeWidth: "0.1",
                      // border: "none", 
                      borderSpacing: "0.0dvw",
                      margin: "0dvw"
                    }}>
                    {chordSymbol}
                  </text>
                  {/* Add Chord Symbol to center of Pie Chart End */}

                </g>
              );
            })}
          </svg>
        </div>
      ) : (
        <div></div>
      )
      }

      {/* </div> {/* </>close pie container */}
      {/*  Pie Chart End */}



      {/* Chord Chart Display */}
      {chordChart.length > 0 ? (
        <div className="chord-chart"
          style={{
            fontSize: `${1.9 / (1 + chartLength * 0.0005)}dvw`,
            lineHeight: `${3.1 / (1 + chartLength * 0.0008)}dvw`
          }}
        >
          <div className="song-and-composer-container">


            {/* <div className="slider-parent">
                <input
                  type="range"
                  min="10"
                  max="700"
                  value={tempoSliderValue}
                  onChange={handleSliderChange}
                  style={{ height: '5dvw' }}
                />
                <div className="buble">
                  {tempoSliderValue}
                </div>
              </div> */}

            <div style={{ top: '0.95dvw', position: showSlider ? 'absolute' : 'absolute' }}>

              {showSlider ? (
                <div className="slider-parent">
                  <input
                    type="range"
                    min="5"
                    max="500"
                    value={tempo.value}
                    onChange={handleSliderChange}
                    style={{ height: '2dvw', marginTop: '0.9dvw', marginLeft: '-5.8dvw' }}
                    onMouseUp={toggleEditTempo} // Hide slider on mouse release
                  />
                  <div className="buble" style={{ fontSize: `${1.4 / (1 + chartLength * 0.0005)}dvw` }}>
                    {tempo.value}
                  </div>
                </div>
              ) : (
                <div
                  style={{ fontSize: `${1.4 / (1 + chartLength * 0.0005)}dvw` }}
                  className="song-tempo"
                  onClick={toggleEditTempo}
                >
                  {tempo.value}
                </div>
              )}
            </div>

            {/* Tempo */}
            {/* <span style={{ fontSize: "0.6dvw" }}></span>
            {tempo.isEditable ? (
              <input
                className="song-tempo-input"
                type="text"
                value={tempo.value}
                onChange={handleTempoChange}
                onBlur={toggleEditTempo}
                autoFocus
                onFocus={(e) => e.target.select()}
              />
            ) : (
              <div style={{ fontSize: `${0.9 / (1 + chartLength * 0.002)}dvw` }}
                className="song-tempo" onClick={toggleEditTempo}>
                {tempo.value}
              </div>
            )} */}

            {/* Song Title */}
            {songTitle.isEditable ? (
              <input
                className="song-title-input"
                type="text"
                value={songTitle.title}
                onChange={handleTitleChange}
                onBlur={toggleEditTitle}
                autoFocus
                onFocus={(e) => e.target.select()}
              />
            ) : (
              <div style={{ fontSize: `${1.8 / (1 + songTitleLength * 0.01)}dvw` }}
                className="song-title" onClick={toggleEditTitle}>
                {songTitle.title}
              </div>
            )}

            {/* Composer */}
            {composer.isEditable ? (
              <input
                className="composer-input"
                type="text"
                value={composer.name}
                onChange={handleComposerChange}
                onBlur={toggleEditComposer}
                autoFocus
                onFocus={(e) => e.target.select()}
              />
            ) : (
              <div style={{ fontSize: `${1.4 / (1 + songTitleLength * 0.02)}dvw` }}
                className="composer" onClick={toggleEditComposer}>
                {composer.name}
              </div>
            )}
          </div>

          <div style={{ marginTop: '2dvw' }}>
            <p></p>
          </div>

          {/* Time Signature */}
          <div>
            <select
              style={{ position: 'relative', fontSize: `${1.7 / (1 + chartLength * 0.001)}dvw` }}
              className='time-signature'
              value={timeSigBeats}
              onChange={(e) => setTimeSigBeats(Number(e.target.value))}>
              {beatOptions.map((beat) => (
                <option key={beat} value={beat}>{beat}</option>
              ))}
            </select><span style={{ position: 'relative', left: '-0.1dvw', fontSize: `${1.7 / (1 + chartLength * 0.0015)}dvw` }} className='time-signature'>/</span>
            <select
              style={{ position: 'relative', fontSize: `${1.7 / (1 + chartLength * 0.001)}dvw` }}
              className='time-signature'
              value={timeSigDuration}
              onChange={(e) => setTimeSigDuration(Number(e.target.value))}>

              {durationOptions.map((dur) => (
                <option key={dur} value={dur}>{dur}</option>
              ))}
            </select>
          </div>

          <span style={{ verticalAlign: "0.1dvw" }}></span>
          <div>
            {'\u00A0'}
            <select
              style={{ position: 'relative', fontSize: `${1.98 / (1 + chartLength * 0.001)}dvw` }}
              className='time-signature'
            >
              <option value="𝄃">𝄃</option>
              <option value="𝄆">𝄆</option>
            </select>
            {'\u00A0\u00A0\u00A0'}
          </div>

          {/* // NEW ADDITION ACRYLICODE */}
          {/* {selectedSong.chordChart.map((entry, index) => ( */}
          {chordChart.map((entry, index) => (
            <React.Fragment key={index}>

              {/* {!entry.isSeparator || entry.name !== SEPARATORS.barline ?
                <span className="chord-space"></span> : null} */}

              {entry.isSeparator ? (
                // Render a select menu for separators with click handler for selection
                <select
                  tabIndex={1}
                  onFocus={() => setSelectedChordIndex(index)}
                  style={{ position: 'relative', bottom: '0px', fontSize: `${2.5 / (1 + chartLength * 0.001)}dvw` }}
                  className={`${selectedChordIndex === index ? 'selected-separator' : 'separator'}`}
                  value={entry.name}
                  onChange={(e) => updateSeparator(index, e.target.value)}
                >
                  {Object.entries(SEPARATORS).map(([key, separator]) => (
                    <option key={key} value={separator}>{separator}</option>
                  ))}
                </select>
              ) : (
                // Render the chord with selection functionality
                <span
                  tabIndex={0}
                  className={`chord ${selectedChordIndex === index ? 'selected-chord' : ''}`}
                  title={`${selectedChordIndex === index ? 'Play Chord' : 'Select Chord'}`}
                  style={selectedChordIndex === index && entry.name !== '\u2001'
                    ? { backgroundColor: mode === 0 ? 'rgb(90,90,0)' : 'rgb(50,50,140)' } // when mode is 0 major or 1 minor and chord is selected
                    : {} // No backgroundColor applied when the chord is not selected
                  }
                  onClick={() => handleChordSelection(index)}
                >
                  {entry.name}
                </span>
              )}

              {!entry.isSeparator && entry.name !== '\u2001' ? // || entry.name !== SEPARATORS.barline ?
                <span className="chord-space"></span> : null}

              {/* {index < chordChart.length - 1 && <span className="chord-space"> </span>} */}
            </React.Fragment>
          ))}



          <div>
            {'\u00A0\u00A0\u00A0'}
            <select
              style={{ position: 'relative', fontSize: `${1.98 / (1 + chartLength * 0.001)}dvw` }}
              className='time-signature'
            >
              <option value="𝄂">𝄂</option>
              <option value="𝄇">𝄇</option>
            </select>
          </div>

          {/* Chord Chart Button Menu */}
          <div className='chord-chart-controls'>
            <div>
              <button title="Insert Separator" className='add-separator' onClick={() => addEntryToChart(SEPARATORS.barline, true)}>
                <span>/</span></button>
            </div>

            <div>
              <button title="Insert Blank Chord" className='insert-blank-chord' onClick={() => addEntryToChart('\u2001', false)}>
                <span>+</span></button>
            </div>

            <div>
              <button title="Copy Chord Chart" className='copy-chart' onClick={copyChordChartToClipboard}>
                <span>{'\u29C9'}</span></button>
            </div>

            {/* // NEW ADDITION ACRYLICODE */}
            {/* <div>
              <button title="Save" onClick={() => saveSong({
                songTitle: songTitle.title,
                composer: composer.name,
                chordChart: chordChart,
                timeSigBeats: timeSigBeats,
                timeSigDuration: timeSigDuration,
              })}>
                <span>{'\u29C9'}</span></button>
            </div> */}

            <div>
              <button data-actionable='true' className='chart-nav backward' title='Previous Chord'
                onClick={() => chartNavigation("backward")} disabled={chordChart.length < 2}>{'\u276E'}</button>
              <button data-actionable='true' className='chart-nav forward' title='Next Chord'
                onClick={() => chartNavigation("forward")} disabled={chordChart.length < 2}>{'\u276F'}</button>
            </div>
            {/* u2702 is scissors, \u2398 is paste, &larr; &rarr; are arrows */}

            <div>

              {/* {selectedChordIndex === index && ( */}
              <button data-actionable="true" title="Delete Entry" className={`${selectedChordIndex || selectedChordIndex === 0 ? 'delete-entry' : 'delete-disabled'}`}
                onClick={() => { selectedChordIndex !== null ? deleteChordFromChart(selectedChordIndex) : '' }}>
                <text>{' \u232B'}</text>
              </button>

              <button
                onClick={(event) => {
                  // setPlaySongOn(prev => !prev);
                  handlePlaySongToggle(event);
                }}
                style={{
                  color: playSongOn ? 'white' : 'black',
                  textShadow: playSongOn ? '0 0 15px #fff, 0 0 15px #fff, 0 0 32px #bbb, 0 0 42px #bbb,0 0 62px #bbb' : '',
                }}
                className='play-song' title={`${playSongOn ? 'Pause Song' : 'Play Song'}`}
              >{'\u25B6'}ll</button>
              {/* {playSongOn ? '\u25FC' : '\u25B6'} */}
            </div>

            <div>
              <button
                className='history undo'
                title='Undo'
                onClick={undo}>
                <span>↺</span>
              </button>
              <button
                className={`history ${currentHistoryIndex >= chordChartHistory.length - 1 ? 'redo-disabled' : 'redo'}`}
                title='Redo'
                onClick={redo}
                disabled={currentHistoryIndex >= chordChartHistory.length - 1}>
                <span>↻</span>
              </button>
            </div>
          </div>
        </div>

      ) : (
        <span></span>
      )}
      {/* Chord Display End */}

      {/*
        \uD834\uDD34 = common time
        \uE08E = slash
        \u00A0 = space
        \uE084 = time signature 4
        \uD834\uDD03 = Double bar start
        \uD834\uDD02 = Double bar end
      */}



      <div id="image-container" style={{ position: 'absolute', top: '0', right: '45.5dvw', transform: 'translate(-0dvh, -0dvh)' }}>
        <span className='title-text' style={{ fontSize: '1.9dvw', fontWeight: '700' }}>sentisonics</span>
        <span className='title-text' style={{ fontSize: '0.7dvw' }}> BETA</span>
        {/* {<img src="/images/sentisonics-logo.jpg" alt="sentisonics" style={{ width: '12dvw' }} />}<text style={{ verticalAlign: '0.8em', fontSize: '0.8dvw', color: 'grey' }}>BETA</text> */}
      </div>



      {/* ColorKey display Below */}
      <div className="color-key-container" style={{
        width: '14dvw', height: '16.3dvw', padding: '0.5dvw',
        marginLeft: '0.5dvw', marginRight: '0dvw', marginTop: '1dvw', marginBottom: '1.0dvw',
        borderStyle: 'none', borderColor: '#fff', borderSpacing: '2dvw', borderRadius: '1dvw', borderWidth: '0.0dvw', boxShadow: '0 0 0.dvw 0.0dvw #aaa'
      }}>
        <p className="title-text">Key-Center</p><hr style={{
          backgroundColor: '#fff', fontSize: '0.3dvw', width: '100%', border: '0.03dvw solid #fff', boxShadow: '0 0 0.3dvw 0.2dvw #444'
        }}></hr>

        <div className="key-display" style={{
          color: lockedNote !== null ? '#fff' : '#bbb',
        }}>{keyNameDisplay}
          {/* {midiToName(rootNote, false, keyCenter, displayedMode)} */}
          <div className="boxed" style={{
            border: lockedNote !== null || isScale !== false ? '0.08dvw solid #fff' : '0.08dvw solid #000',
            // textShadow: lockedNote !== null ? '0 0 15px #fff, 0 0 15px #fff, 0 0 32px #bbb, 0 0 42px #bbb,0 0 62px #bbb' : undefined,
          }}>
            {/* <p style={{ color: 'white' }}>{rootNote}</p> */}</div>
        </div>

        <div className="mode-select-container">
          <select
            className="mode-select"
            style={{
              fontSize: '1vw',
              fontFamily: 'Bravura Text',
              color: isScale ? '#444' : (lockedNote !== null ? '#fff' : '#bbb'),
              border: lockedNote !== null ? '0.08dvw solid #fff' : '0.08dvw solid #222'
            }}
            id="mode-select"
            onChange={handleModeChange}
            value={lockedNote === null ? mode.toString() : (lockedMode === null ? '' : lockedMode.toString())}
          >
            <option value="0">Major</option>
            <option value="1">Minor</option>
          </select>
        </div>

        <div id="color-key" style={{ height: '10dvh' }}>

          {[0, 2, 4, 5, 7, 9, 11].map((note) => (
            <ColorKey
              note={note}
              isBlack={false}
              rootNote={rootNote}
              setRootNote={setRootNote}
              isActive={activePcs.includes(note)}
              isScale={isScale}
              // isActive={activeNotes.includes(note % 12) || heldNotes.includes(note % 12) || toggledNotes.includes(note % 12)}

              toggleLock={lockedNote}
              setToggleLock={handleToggleLock}
            // changeKey={nudgeToggledNotes} impliment this!!!
            // lockedMode={mode}
            // setLockedMode={setLockedMode}

            />
          ))}
          {[1, 3, 6, 8, 10].map((note, index) => {
            // Calculate the left offset based on the position of the black key
            const positionInOctave = oneOctblackKeyPos[index % 5];
            const leftOffset = ((positionInOctave / 0.915) * 12) + 8.7; // 12 total keys in one octave

            return (
              <ColorKey
                note={note}
                isBlack={true}
                rootNote={rootNote}
                setRootNote={setRootNote}
                isActive={activePcs.includes(note)}
                isScale={isScale}
                // isActive={activeNotes.includes(note) || heldNotes.includes(note) || toggledNotes.includes(note)}
                style={{ left: `${leftOffset}%` }}

                toggleLock={lockedNote}
                setToggleLock={handleToggleLock}
              // changeKey={nudgeToggledNotes} impliment this!!!
              // lockedMode={mode}
              // setLockedMode={setLockedMode}

              />
            );
          })}
        </div>
      </div>
      {/* ColorKey display End */}


      {/* Keyboard Reset, MIDI Device Select */}
      <div id="keyboard-inputs">


        <div style={{ position: 'absolute', top: '0dvw', left: '0.5dvw' }}>
          {midiAccess && (
            <select
              style={{ fontSize: '0.8vw' }}
              value={selectedInputId || ''}
              onChange={event => setSelectedInputId(event.target.value)}
            >
              <option value="">Select a MIDI input device</option>
              {Array.from(midiAccess.inputs.values()).map(input => (
                <option key={input.id} value={input.id}>{input.name}</option>
              ))}
            </select>
          )}
        </div>

        {/* <div style={{ position: 'absolute', top: '5.5dvw', left: '1dvw' }}>
          <button onClick={() => setSoundOn(!soundOn)}>
            {soundOn ? "Sound Off" : "Sound On"}
          </button>
        </div> */}

        <div style={{ position: 'absolute', top: '2.7dvw', left: '8px', color: 'grey', fontSize: '1dvw' }}>
          <button
            onClick={(event) => {
              setSoundOn(prev => !prev);
              handleSoundToggle(event);
            }}
            style={{
              // fontWeight: soundOn ? 'bold' : 'normal',
              width: '12dvw',
              color: soundOn ? 'white' : 'black',
              textShadow: soundOn ? '0 0 15px #fff, 0 0 15px #fff, 0 0 32px #bbb, 0 0 42px #bbb,0 0 62px #bbb' : '',
              // backgroundColor: soundOn ? '#888' : '#555'
            }}
          >
            {soundOn ? ": : : \u00A0 Sound On \u00A0 : : :" : ": : : \u00A0 Sound Off \u00A0 : : :"}
          </button>

          {/* <p className='pie-chord-symbol'>{Tone.context.state}</p> */}

          {/* <button onClick={handleTestSound}>Test Sound</button> */}
        </div>

        <div style={{ position: 'absolute', top: '11.5dvw', left: '8px', height: '1dvw' }}>

          <button data-actionable="true" title="Octave Down" onClick={() => nudgeToggledNotes(-12)}
            style={{ width: '2.8dvw' }}
          // style={{ color: 'black', fontSize: '1.3dvw', border: '10px', fontFamily: 'Trebuchet MS', fontStyle: 'bold', backgroundColor: 'rgb(110,110,140)' }}
          ><span style={{ fontSize: '0.8dvw', fontWeight: '100' }}>-12</span></button><span style={{ fontSize: '1dvw' }}>&nbsp;</span>

          <button data-actionable="true" title="Step Down" onClick={() => nudgeToggledNotes(-1)}
            style={{ width: '2.8dvw' }}
          // style={{ color: 'black', fontSize: '1.3dvw', border: '10px', fontFamily: 'Trebuchet MS', fontStyle: 'bold', backgroundColor: 'rgb(110,110,140)' }}
          ><span style={{ fontSize: '0.8dvw', fontWeight: '100' }}>-1</span></button><span style={{ fontSize: '1dvw' }}>&nbsp;</span>

          <button data-actionable="true" title="Step Up" onClick={() => nudgeToggledNotes(1)}
            style={{ width: '2.8dvw' }}
          // style={{ color: 'black', fontSize: '1.3dvw', border: '10px', fontFamily: 'Trebuchet MS', fontStyle: 'bold', backgroundColor: 'rgb(110,110,140)' }}
          ><span style={{ fontSize: '0.8dvw', fontWeight: '100' }}>+1</span></button><span style={{ fontSize: '1dvw' }}>&nbsp;</span>

          <button data-actionable="true" title="Octave Up" onClick={() => nudgeToggledNotes(12)}
            style={{ width: '2.8dvw' }}
          // style={{ color: 'black', fontSize: '1.3dvw', border: '10px', fontFamily: 'Trebuchet MS', fontStyle: 'bold', backgroundColor: 'rgb(110,110,140)' }}
          ><span style={{ fontSize: '0.8dvw', fontWeight: '100' }}>+12</span></button>
        </div>

        {/* <div style={{ position: 'absolute', top: '5.5dvw', left: '8px', color: 'grey', fontSize: '1dvw' }}>
          <button style={{ width: "12dvw" }} data-actionable="true" onClick={playChord}>Play Chord {'\u25B6'}</button>
        </div> */}

        <div style={{
          position: 'absolute', top: '5.5dvw', left: '8px',
          display: 'flex', // Use flexbox for horizontal layout
          alignItems: 'center', // Align items vertically in the center
          color: 'grey', fontSize: '1dvw'
        }}>
          {/* Arpeggiate Toggle Button */}
          <button title="Arpeggiate" onClick={() => setIsArpeggiateOn(prev => !prev)} data-actionable="true"
            style={{
              width: '4.6dvw', height: "2.85dvw", color: isArpeggiateOn ? 'white' : 'black',
              textShadow: isArpeggiateOn ? '0 0 15px #fff, 0 0 15px #fff, 0 0 32px #bbb, 0 0 42px #bbb, 0 0 62px #bbb' : '',
            }}
          >
            {isArpeggiateOn ? "Arp. \u266C" : "Arp. \u266C"}
          </button>
          {/* Play Chord Button */}
          <button style={{ width: "7.4dvw", height: "2.85dvw", marginRight: '0.0dvw' }} data-actionable="true" title="Play Chord" onClick={playChord}>
            Play Chord {'\u25B6'}
          </button>
        </div>

        <div style={{ position: 'absolute', top: '8.4dvw', left: '8px', height: '1dvw' }}>
          <button style={{ width: "12dvw" }} data-actionable="true" title="Clear Keyboard" onClick={clearAllNotes}
          // style={{ color: 'black', fontSize: '1.3dvw', border: '10px', fontFamily: 'Trebuchet MS', fontStyle: 'bold', backgroundColor: 'rgb(110,110,140)' }}
          >Reset Keyboard <span style={{ fontSize: '1.2dvw' }}>{'\u21Ba'}</span></button>
        </div>

      </div>


      {/* Main Piano display Below */}
      <div data-actionable="true" id="piano" style={{ height: '10dvw' }}>
        {whiteNotes.map((note) => (
          <Key
            key={note}
            note={note}
            isBlack={false}
            keyflash={keyflash.includes(note)}
            // style={{ '--activeColor': activeColor } as any}
            isActive={activeNotes.includes(note) || heldNotes.includes(note) || toggledNotes.includes(note)}
            rootNote={rootNote}
            toggleNote={toggleNote}
            toggledNotes={toggledNotes}
            keyCenter={keyCenter}
            mode={mode}
            sampler={samplerRef.current}
            shouldPlaySound={shouldPlaySoundRef.current}
          // shouldPlaySoundRef={shouldPlaySoundRef.current}
          />

        ))}
        {blackNotes.map((note, index) => {
          // Calculate the left offset based on the position of the black key
          const octave = Math.floor((note - 21) / 12); // 21 is A0
          const positionInOctave = blackKeyPositions[index % 5];
          const leftOffset = ((octave * 7 + positionInOctave) / 88) * 169.2 - 0.62; // 7 white keys per octave, 88 total keys

          return (
            <Key
              key={note}
              note={note}
              isBlack={true}
              keyflash={keyflash.includes(note)}
              // style={{ left: `${leftOffset}%`, '--activeColor': activeColor } as any}
              style={{ left: `${leftOffset}%` }}
              isActive={activeNotes.includes(note) || heldNotes.includes(note) || toggledNotes.includes(note)}
              rootNote={rootNote}
              toggleNote={toggleNote}
              toggledNotes={toggledNotes}
              keyCenter={keyCenter}
              mode={mode}
              sampler={samplerRef.current}
              shouldPlaySound={shouldPlaySoundRef.current}
            // shouldPlaySoundRef={shouldPlaySoundRef.current}
            />
          );
        })}
      </div>
      {/* Main Piano display End */}


      {/* Piano bars display Below */}

      {/* <div className="piano-bars">
        {notes.map((note) => (
          <Bar
            key={note}
            note={note}
            isActive={activeNotes.includes(note) || heldNotes.includes(note) || toggledNotes.includes(note)}
            rootNote={rootNote}
            width={100 / 12}
          />
        ))}
      </div> */}

      {/* <div className="piano-bars-key">
        {notes.map((note) => (
          <Bar
            key={note}
            note={note}
            isActive={activeNotes.includes(note) || heldNotes.includes(note) || toggledNotes.includes(note)}
            rootNote={rootNote}
            width={100 / 12}
          />
        ))}
      </div> */}

      {/* Piano bars display End */}




      {/* Customize Emotion/Feeling Below */}
      <div id="emo-inputs">
        <div style={{ position: 'absolute', top: '0px', left: '0px' }}>
          <p><br></br></p><div className="inputs">

            <table style={{ top: '0dvw', position: 'absolute', fontFamily: 'Trebuchet MS', fontSize: '1.1dvw', padding: '0px', margin: '0px' }}>
              <tr>
                <th></th>
                <th style={{ fontWeight: '600' }}>Minor</th>
                <th>Major</th>
              </tr>
              {reorder.map(index => {
                const title = pcClassLabels[index];
                const tableClassName = pcClass[index];
                return (
                  <tr key={index}>
                    <th className={tableClassName} style={{ paddingRight: '0.2dvw', color: 'black' }}>{title}</th>
                    <td style={{ fontFamily: 'Bravura Text', fontWeight: 100, padding: '0px', margin: '0px' }}>
                      <input
                        type="text"
                        value={emoClassLabelsMinor[index]}
                        onChange={handleInputChangeMinor(index)}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        style={{ color: "rgb(170, 170, 170)", backgroundColor: "rgb(70, 70, 120)", border: '0px', paddingTop: '0.7dvw', paddingBottom: '0.7dvw', margin: '0px' }}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        value={emoClassLabelsMajor[index]}
                        onChange={handleInputChangeMajor(index)}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        style={{ color: "rgb(30, 30, 30)", backgroundColor: "rgb(245, 235, 190)", border: '0px', paddingTop: '0.7dvw', paddingBottom: '0.7dvw', margin: '0px' }}
                      />
                    </td>
                  </tr>
                )
              })}
            </table>
          </div>

          <div id='emo-selects'>
            <div>


              <p className="title-text">Sentiment Palette</p>
              <br></br>

              {/* // NEW ADDITION ACRYLICODE */}
              {/* <select style={{ width: '12vw', fontSize: '1.0vw' }}
              value={selectedSong.songTitle}
              onChange={event => {
                const selectedSong = allSongs.find(({ songTitle }) => songTitle === event.target.value)
                if (!selectedSong) return
                setSelectedSong(selectedSong)
                // SET ALL THE STATE VARIABLES HERE
                setSongTitle({ title: selectedSong.songTitle, isEditable: false })
              }}
            >
              {/* {allPalettes.map(({ label }) => <option value={label}>{label}</option>)} */}

              {/* {allSongs.map(({ songTitle }) => (<option key={songTitle} value={songTitle}>{songTitle}</option>))}
              <hr />
            </select> */}

              <select style={{ width: '12dvw', fontSize: '1.0dvw', position: 'relative', top: '0.0dvw' }}
                value={selectedPalette}
                onChange={event => {
                  setSelectedPalette(event.target.value)
                  const selectedPalette = allPalettes.find(({ label }) => label === event.target.value)
                  if (!selectedPalette) return
                  setEmoClassLabelsMajor(selectedPalette.majorLabels)
                  setEmoClassLabelsMinor(selectedPalette.minorLabels)
                }}
              >
                {/* {allPalettes.map(({ label }) => <option value={label}>{label}</option>)} */}

                {defaultPalettes.map(({ label }) => (<option key={label} value={label}>{label}</option>))}
                <hr />
                {userPalettes.map(({ label }) => (<option key={label} value={label}>{label}</option>))}

              </select>
              {'\u0020\u0020'}
              <button style={{ width: "12dvw", position: 'relative', top: '0.0dvw' }}
                className={defaultPalettes.map(p => p.label).includes(selectedPalette) ? 'button-disabled' : ''}
                disabled={defaultPalettes.map(p => p.label).includes(selectedPalette)}
                onClick={() => {
                  deletePalette(email, selectedPalette);
                  setSelectedPalette(allPalettes[0].label);
                  setEmoClassLabelsMajor(allPalettes[0].majorLabels);
                  setEmoClassLabelsMinor(allPalettes[0].minorLabels);
                }}>Delete Palette<text style={{ fontSize: '0.8dvw' }}>{' \u232B'}</text></button>

            </div>

            <button style={{ width: "12dvw", position: 'relative', top: '0.0dvw' }}
              className={defaultPalettes.map(p => p.label).includes(selectedPalette) || hasChanges() ? 'button-disabled' : ''}
              disabled={defaultPalettes.map(p => p.label).includes(selectedPalette) || hasChanges()}
              onClick={() => {
                updatePalettes(email, selectedPalette, emoClassLabelsMajor, emoClassLabelsMinor)
              }}>Save <text style={{ fontSize: '0.9dvw' }}>{'\u2713'}</text></button>
            {'\u0020'}
            <button style={{ width: "12dvw", position: 'relative', top: '0.0dvw' }}
              className={hasChanges() ? 'button-disabled' : ''}
              disabled={hasChanges()}
              onClick={() => {
                const currentPalette = allPalettes.find(({ label }) => label === selectedPalette)
                if (!currentPalette) return
                setEmoClassLabelsMajor(currentPalette.majorLabels);
                setEmoClassLabelsMinor(currentPalette.minorLabels);
              }}>Discard Changes <text style={{ fontSize: '1.0dvw' }}>{'\u21Ba'}</text></button>
            <div>
              <button style={{ width: "12dvw", position: 'relative', top: '0.0dvw' }}
                className={customPaletteName === '' || allPalettes.map(p => p.label).includes(customPaletteName) ? 'button-disabled' : ''}
                disabled={customPaletteName === '' || allPalettes.map(p => p.label).includes(customPaletteName)}
                onClick={() => {
                  setSelectedPalette(customPaletteName)
                  setEmoClassLabelsMajor(emoClassLabelsMajor)
                  setEmoClassLabelsMinor(emoClassLabelsMinor)
                  updatePalettes(email, customPaletteName, emoClassLabelsMajor, emoClassLabelsMinor)
                  setCustomPaletteName('')
                }}>Save As...</button>
              {'\u0020'}
              <input style={{
                width: '11.5dvw', height: '1.6dvw', fontSize: '1.0dvw', textIndent: '5px',
                color: "rgb(250, 250, 250)", backgroundColor: "rgb(0, 0, 0)", fontStyle: "normal"
              }}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                type="text" placeholder='New Palette Name'
                value={customPaletteName}
                onChange={(event) => setCustomPaletteName(event.target.value)}></input>
            </div>
            {!email && <button onClick={() => navigate('/login')}>Login</button>}
            {email && <button style={{ width: "12dvw", position: 'relative', top: '0.0dvw' }} onClick={() => signOut(auth)}>Logout</button>}

          </div>
        </div>
      </div>
      {/* Customize Emotion/Feeling End */}




      {/* <div style={{position: 'absolute', bottom: '0px', right: '8px'}}>
    <table>
        {pcLabelDisplayOrder.map((title, index) => (
            <tr key={index}>
                <td style={{paddingRight: '0px'}}>{title}</td>
                <td>
                    <input 
                        type="text" 
                        value={emoLabelMajorDisplayOrder[index]} 
                        onChange={handleInputChangeMajor(index)}
                        style={{margin: "0px", height: "13px"}}
                    />
                </td>
            </tr>
        ))}
    </table>
  </div> */}



    </>
  );
}

export default App;
