import React, { useEffect, useState } from "react"
import '../App.css';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth, firestore } from "../firebase";
import { collection, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { defaultEmoClassLabelsMajor, defaultEmoClassLabelsMinor, server } from "../App";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { stringify } from "querystring";

function downloadURI(uri: string, name: string) {
    var link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', name);
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export function TdContest() {

    const [email, setEmail] = useState('')
    const [submissionLink, setSubmissionLink] = useState('')
    const [feedback, setFeedback] = useState('')
    const [uid, setUid] = useState('')
    const [uidIsShown, setUidShow] = useState(false)
    useEffect(() => {
        auth.onAuthStateChanged(
            (user) => {
                if (user && user.email) {
                    setEmail(user.email)
                    setUid(user.uid)
                } else {
                    setEmail('')
                }
            },
            (e) => {
                console.log('error', e)
            }
        )
    }, [])

    useEffect(() => {
        const storedEmail = localStorage.getItem('tdcontest') || email
        console.log('stored Email', storedEmail)
        async function reload(email: string) {
            const user = await signInWithEmailAndPassword(auth, email, password)
            setUidShow(true)
            const userRef = doc(firestore, 'tdcontest/' + user.user.uid)
            const snapshot = await getDoc(userRef)
            const data = snapshot.data() || { submissionLink: '' }
            console.log(data)
            setSubmissionLink(data['submissionLink'])
            // download
            // downloadURI('/ContestContent.zip', 'ContestContent - ' + user.user.uid)
        }
        if (storedEmail) {
            reload(storedEmail)
            setEmail(storedEmail)
            console.log('stored Email', storedEmail)
        }
    }, [uidIsShown])

    const register = async (userid: string) => {
        await setDoc(doc(firestore, "tdcontest", userid), {
            submissionLink: '',
            feedback: ''
        });
        setUidShow(true)
        // download
        // downloadURI('/ContestContent.zip', 'ContestContent - ' + userid)
        localStorage.setItem('tdcontest', email)
        console.log('saving email', email)
    }

    const [password, setPassword] = useState('fakepass')
    const navigate = useNavigate()

    const signup = async () => {
        console.log(email)
        console.log(password)

        try {
            const user = await signInWithEmailAndPassword(auth, email, password)
            console.log(user)
            setUidShow(true)
            localStorage.setItem('tdcontest', email)
            const userRef = doc(firestore, 'tdcontest/' + user.user.uid)
            const snapshot = await getDoc(userRef)
            const data = snapshot.data() || { submissionLink: '' }
            console.log(data)
            setSubmissionLink(data['submissionLink'])
            // download
            // downloadURI('/ContestContent.zip', 'ContestContent - ' + user.user.uid)
        } catch (e) {
            console.error(e)
            // alert("Wrong email or password")
            try {
                const user = await createUserWithEmailAndPassword(auth, email, password)
                console.log(user)
                register(user.user.uid)
            } catch (e) {
                console.log(e)
                // console.error(e)
                // Will throw this error if user already has a password or invalid characters etc.
                alert("Something went wrong (If you're a Sentisonics Beta Tester please use a different email for this contest)")
            }
        }
    }

    const submit = async () => {
        await setDoc(doc(firestore, "tdcontest", uid), {
            submissionLink
        });
    }

    const changeEmail = () => {
        localStorage.removeItem('tdcontest')
        window.location.reload()
    }

    const Icon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9.75 14.25 12m0 0 2.25 2.25M14.25 12l2.25-2.25M14.25 12 12 14.25m-2.58 4.92-6.374-6.375a1.125 1.125 0 0 1 0-1.59L9.42 4.83c.21-.211.497-.33.795-.33H19.5a2.25 2.25 0 0 1 2.25 2.25v10.5a2.25 2.25 0 0 1-2.25 2.25h-9.284c-.298 0-.585-.119-.795-.33Z" />
        </svg>
    )

    return (
        <div className="inputs" style={{ position: 'absolute', top: '0dvw', width: '100%', textAlign: 'center', fontSize: '1.3dvw', color: 'rgb(150,150,150', alignItems: 'center' }}>
            <div style={{ position: 'absolute', width: '100%', top: '1', textAlign: 'center' }}>
                <span className='title-text' style={{ fontSize: '3.9dvw', fontWeight: '700' }}>sentisonics</span>
                <span className='title-text' style={{ fontSize: '0.9dvw' }}> BETA</span>
                {/* {<a href="/"><img src="./images/sentisonics-logo.jpg" alt="sentisonics" style={{ width: '26dvw' }} /></a>} */}
            </div>
            <div style={{ position: 'absolute', width: '100%', top: '8dvw', textAlign: 'center' }}>
                <p>Signup FOR THE CONTEST<br></br>
                    Or choose Login if you already have an account</p>
                <p>To use with a MIDI controller, open the app in Chrome or Firefox browsers.</p>
                <Icon />
                <p>
                    <input readOnly={uidIsShown} value={email} style={{ width: '15dvw', height: '1.5dvw' }} placeholder="email" onInput={(e) => setEmail((e.target as HTMLInputElement).value)} />
                    {'\u0020'}
                    {uidIsShown && <button onClick={changeEmail}>Change Email</button>}
                    {/* <input style={{ width: '11dvw', height: '1.5dvw' }} placeholder="password" onInput={(e) => setPassword((e.target as HTMLInputElement).value)} type='password' /> */}
                </p>
                {!uidIsShown ? <button style={{ fontSize: '1.3dvw', width: '12dvw' }} onClick={signup}>Register</button> : <div> Use this code to access the Sentisonics TouchDesigner tox:<br></br><br></br> {uid}</div>}
                {'\u0020'}

                {uidIsShown &&
                    <div>
                        <br></br>
                        <button onClick={() => downloadURI('/ContestContent.zip', 'ContestContent - ' + uid)}>Download Tox</button>
                        <br></br>
                        <br></br>
                        <input value={submissionLink} style={{ width: '15dvw', height: '1.5dvw' }} placeholder="Contest submission URL" onInput={(e) => setSubmissionLink((e.target as HTMLInputElement).value)} />
                        <button onClick={submit}>Submit</button>
                        <br></br>
                        <textarea onInput={(e) => setFeedback((e.target as HTMLInputElement).value)} placeholder="We'd love to hear your feedback" name="" id="" cols={30} rows={10}></textarea>
                        <button onClick={async () => {
                            await updateDoc(doc(firestore, "tdcontest", uid), {
                                feedback
                            });
                            alert('Feedback received')
                        }}>Submit Feedback</button>
                    </div>}
                {/* <button style={{ fontSize: '1.3dvw', width: '12dvw' }} onClick={() => login()}>Login</button> */}

                <p>Signup for beta testing at <a href="http://www.sentisonics.com/betatesting" style={{ fontSize: '1.2dvw', color: 'white' }}>sentisonics.com</a></p>
            </div>
        </div >
    )


}
