import React, { useState } from "react"
import '../App.css';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth, firestore } from "../firebase";
import { collection, doc, setDoc } from "firebase/firestore";
import { defaultEmoClassLabelsMajor, defaultEmoClassLabelsMinor, server } from "../App";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { stringify } from "querystring";

export function Login() {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate()

    const signup = async () => {
        console.log(email)
        console.log(password)

        const response = await axios.post(server+"/isbetatester", JSON.stringify({email}), {headers:{"Content-Type":"application/json"}})
        console.log(response.data)
        if (!response.data["isbetatester"]){
            alert("Please sign up for beta testing at sentisonics.com")
            return
        }

        try {
            const user = await createUserWithEmailAndPassword(auth, email, password)
            console.log(user)
            navigate('/')
        } catch (e) {
            console.log(e)
            // console.error(e)
            alert("Something went wrong")
        }
    }

    const login = async () => {
        try {
            const user = await signInWithEmailAndPassword(auth, email, password)
            console.log(user)
            navigate('/')
        } catch (e) {
            console.error(e)
            alert("Wrong email or password")
        }
    }

    return (
        <div className="inputs" style={{ position: 'absolute', top: '0dvw', width: '100%', textAlign: 'center', fontSize: '1.3dvw', color: 'rgb(150,150,150', alignItems: 'center' }}>
            <div style={{ position: 'absolute', width: '100%', top: '1', textAlign: 'center' }}>
            <span className='title-text' style={{ fontSize: '3.9dvw', fontWeight: '700' }}>sentisonics</span>
        <span className='title-text' style={{ fontSize: '0.9dvw'}}> BETA</span>
                {/* {<a href="/"><img src="./images/sentisonics-logo.jpg" alt="sentisonics" style={{ width: '26dvw' }} /></a>} */}
            </div>
            <div style={{ position: 'absolute', width: '100%', top: '8dvw', textAlign: 'center' }}>
            <p>Signup with the same email you submitted at sentisonics.com<br></br>
            Or choose Login if you already have an account</p>
            <p>To use with a MIDI controller, open the app in Chrome or Firefox browsers.</p>
            <p>
            <input style={{ width: '15dvw', height: '1.5dvw' }} placeholder="email" onInput={(e) => setEmail((e.target as HTMLInputElement).value)} />
            {'\u0020'}
            <input style={{ width: '11dvw', height: '1.5dvw' }} placeholder="password" onInput={(e) => setPassword((e.target as HTMLInputElement).value)} type='password' />
            </p>
            <button style={{ fontSize: '1.3dvw', width: '12dvw' }} onClick={() => signup()}>Signup</button>
            {'\u0020'}
            <button style={{ fontSize: '1.3dvw', width: '12dvw' }} onClick={() => login()}>Login</button>

            <p>Signup for beta testing at <a href="http://www.sentisonics.com/betatesting" style={{ fontSize: '1.2dvw', color: 'white' }}>sentisonics.com</a></p>
        </div>
        </div >
    )


}
