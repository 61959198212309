

import React from 'react';
import { getSpellings, midiToName } from '../utils';

type ColorKeyProps = {
  note: number;
  isBlack: boolean;
  rootNote: number;
  setRootNote: (note: number) => void;
  style?: React.CSSProperties;
  isActive: boolean;
  isScale: boolean;
  toggleLock: number | null;
  setToggleLock: (note: number | null) => void;
  // changeKey: number;
};

const ColorKey: React.FC<ColorKeyProps> = ({
  note,
  isBlack,
  rootNote,
  setRootNote,
  isActive,
  isScale,
  style,
  toggleLock,
  setToggleLock,
  // changeKey
}) => {
  const pc = pitchClass(note, rootNote);
  const pcClass = ['p-root', 'min-second', 'maj-second', 'min-third', 'maj-third', 'p-fourth', 'tritone', 'p-fifth', 'min-sixth', 'maj-sixth', 'min-seventh', 'maj-seventh'][pc];
  const isToggledRoot = note === rootNote && toggleLock !== null;
  const isToggleOn = toggleLock !== null;
  // console.log('isToggledRoot and isToggleOn', isToggledRoot, isToggleOn)
  const keyClassName = `color-key ${isBlack ? 'black' : 'white'} ${isToggledRoot ? 'ck-toggled' : ''} ${isToggleOn || isActive ? pcClass : 'inactive-scale-note'}`;

  const keyStyle: React.CSSProperties = {
    ...style,
    borderBottomWidth: isActive || isScale || toggleLock !== null ? '0.1dvw' : toggleLock !== null && isActive ? '0dvw' : '0.4dvw',
    borderTopWidth: !isActive || isScale || toggleLock !== null ? '0.0dvw' : toggleLock !== null && isActive ? '0.1dvw' : '0.4dvw', // fix this to add border for active notes when toggleLock
    borderBottomStyle: !isActive ? 'solid' : toggleLock !== null && isActive ? 'inset' : 'outset',
    borderColor: isScale ? 'rgb(90,90,90' : toggleLock !== null && isActive ? 'white' : 'rgb(40,40,40',
    borderBottomRightRadius: isActive ? '0.3dvw 0.3dvw;' : '0.4dvw 0.4dvw;',
    borderBottomLeftRadius: isActive ? '0.3dvw 0.3dvw;' : '0.4dvw 0.4dvw;',
    // borderColor: isScale ? 'rgb(120,120,120' : 'rgb(40,40,40',
    // opacity: !isScale || (isScale && isActive) ? 1 : 1,
    // opacity: isActive ? 1 : 0.8,
    // borderColor: !isScale || (isScale && !isActive) ? 'rgb(220,220,220' : '#000',
    // borderWidth: !isScale || (isScale && isActive) ? null : 0,
    // borderBottomColor: 'rgb(120,120,120)',
    // borderBottomColor: isActive ? 'grey' : 'rgb(120,120,120)',
    // borderBottomRightRadius: '4px 4px',
    // borderBottomLeftRadius: '4px 4px',
    // borderTopWidth: isBlack && !isActive ? 0 : 4,
    // border: '0.5px solid rgb(120,120,120)',
    // border: !isScale || (isScale && !isActive) ? '0.5px solid rgb(120,120,120)' : '0.8px solid white',
    // color: !isScale || (isScale && isActive) ? '#fff' : '#000',
    // backfaceVisibility: 'hidden'
  };

  // const handleClick = () => {
  //   if (toggleLock === null || toggleLock === note) {
  //     setRootNote(note);
  //     setToggleLock(toggleLock === note ? null : note);
  //   }
  // };

  const handleClick = () => {
    if (!isScale) {
      if (toggleLock === note) { // If the current note is already toggled, untoggle it
        setToggleLock(null);
      } else { // Toggle on the new note and set it as the rootNote
        setRootNote(note);
        setToggleLock(note);
        // const IsToggleOn = true;
      }
    } else {
      // alert("Scale mode on. 'Reset Keyboard' to lock key.")
      if (isToggleOn) {
        setToggleLock(null);
      } else {
        setToggleLock(rootNote);
        setRootNote(rootNote);
      }
    }
  };

  return (
    <div
      title="Lock the key to simulate prior context (key center perception.)&#13;&#13;
• To lock a key center, click a piano key and select 'Major' or 'Minor.' The key is locked to the 'Root' and all scale degree colors are shown. The Sentiments Palette changes to 'Key Center Emotions.'&#13;&#13;
• To unlock, click the piano key again."
      className={keyClassName}
      style={keyStyle}
      onClick={handleClick}>
      {/* <div className="name-display">{midiToName(note, false, 0, 0)}</div> */}
    </div>
  );
};

const pitchClass = (note: number, rootNote: number) => {
  return (note - rootNote + 12) % 12;
};

export default React.memo(ColorKey);
